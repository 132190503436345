import React, { useState, useEffect } from "react";
import { Container, Row, Col, Modal, Button, Image, OverlayTrigger} from 'react-bootstrap';
import Popover from 'react-bootstrap/Popover';
import { Plus, PencilSquare } from 'react-bootstrap-icons';
import { useGlobal } from 'reactn';
import { useHistory } from "react-router-dom";
import { Web3Util } from '../../../utils/index';
import "../../../style/Proposalbuilder.css";



const FunctionRows = (props) => {

    // fetch global data
    const [ functionNames, setFunctionNames] = useState([]);
    const [ chosenFunctionName, setChosenFunctionName] = useState(null);

    useEffect(() => {
        const config = require(`../../../../public/data/proposalbuilder/${props.interface}.json`);
        setFunctionNames(config.functionNames.map((func) => 
            <Row key={func.name} >
                <Col>
                    <Button size="sm" className="pink-choose-function-button pink-button" onClick={() => setChosenFunctionName(func.name)}>{func.name}</Button>
                </Col>
                <Col> {func.description}</Col>
            </Row> 
        ))
    },[])

    return (
        <OverlayTrigger 
            trigger="click" 
            placement="right-start" 
            trigger="focus"
            overlay={
                <Popover id="popover-add-chain">
        
                <Popover.Content>
                    {functionNames}
                </Popover.Content>
            </Popover>
            }
            >
            
                <Button size="sm" className="darkblue-button function-overlay">
                    
                    {chosenFunctionName ? (
                        <div>
                            <PencilSquare size={20}/>&nbsp;{chosenFunctionName}
                        </div>
                    ) : ( 
                        <div>
                            <Plus size={24}/>{props.functionName}
                        </div>
                    )}
                </Button>
            
            
        </OverlayTrigger>
    )

}

export default FunctionRows;