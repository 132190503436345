import React, { useState, useEffect, useContext  } from "react";
import {Button, Row, Col, Container, Card} from 'react-bootstrap';
import nyanGif from '../../assets/nyan-logo.png';
import nyanLogo from '../../assets/logos/cat-logo.png';

import { Accounts, Balances, Web3Util, Contracts, Swap } from "../../utils";
import { Balances as V2Balances, Allowance, LGE as LGEUtil} from '../../utils/V2';

import { useGlobal } from 'reactn';

import AmountField from '../fields/Amount';

import '../../style/LGE.css';

const LGE = (props) => {

    const [amountInput, setAmountInput] = useState(0);
    const [nyanV1Balance, setNyanV1Balance] = useState(0);
    const [nyanV2Balance, setNyanV2Balance] = useState(0);
    const [ethBalance, setEthBalance] = useState(0);
    const [isLGEOver, setIsLGEOver] = useState(true);
    const [swapAllowed, setSwapAllowance] = useState(null);
    const [nyanSwapped, setNyanSwapped] = useState(false);
    const [nyanV2ETH, setNyanV2ETH] = useState(false);
    const [userNyanSwapped, setUserNyanSwapped] = useState(false);

    const [web3Instance] = useGlobal('web3Instance');
    const [swapText, setSwapText] = useState('Swap!');
    const lgeAmountClassNames = {
        rowClassName: 'row-lge-amount',
        colClassName: 'col-lge-amount',
        fieldClassName: 'field-lge-amount',
        maxButtonClassName: 'field-lge-max-button'
    }

    const swapForLGE = () => {
        Swap.swapForLGE(amountInput);
    }

    // init staking page
    useEffect(() => {
        // only fires whenever requestConnectionToWallet is updated.
        async function init() {
            setNyanV1Balance(await Balances.getMyNyanBalance());
            setEthBalance(await V2Balances.getEthBalance());

            // setIsLGEOver(await Swap.getLGEBool());
            setSwapAllowance(await Allowance.getMyNyanSwapAllowed());
            setNyanSwapped(await LGEUtil.getSwappedNyanBalance());
            setNyanV2ETH(await LGEUtil.getNyanV2ETHBalance())
            setUserNyanSwapped(await LGEUtil.getLGEContribution());
          
            

            /* HACK */
            if(web3Instance) return false;
            let instance = await Web3Util.createInstance();
            if(!instance) console.log('cannot open modal')

        }
        init()
    }, []);

    // Check if changes
    useEffect(() => {
        if((amountInput/10) > ethBalance){
            setSwapText('Insufficient ETH')
        } else {
            setSwapText('Contribute to pool');
        }
    }, [amountInput]);

    return (
        <Container fluid>
            <Row className="justify-content-center align-items-center">
                <Col lg="auto">
                    <div styles={{backgroundImage: `url(${nyanGif})`}}></div>
                    <h1 className="title">Swap your tokens early</h1>
                    <p className="text-center">
                        Join the NyanV2 Liquidity pool early and get special bonuses to your mining!&nbsp;
                        <a target="_blank" href="https://www.learnnyan.finance/nyan-2-lge/what-is-the-nyan-2-lge">Learn more</a>
                        <br/>
                    </p>
                    <h3 className="text-center totalSwapped">Total NYAN SWAPPED: {nyanSwapped.formatted}</h3>
                    <h3 className="text-center totalSwapped ">Total ETH in V2 Contract: {nyanV2ETH.formatted}</h3>
                </Col>
            </Row>

            <br/><br/>
            {isLGEOver &&
                <Row className="justify-content-center align-items-center">
                    <Col lg="auto"></Col>

                    <Col lg={5} md={8} sm={12} xs={12} className="text-center">
                        Our LGE has been concluded! 
                        <Card className="actionbox lgebox">
                            <div className="text-center">
                                <Card.Img className="actionbox-logo-image top" variant="top" src={nyanLogo}/>
                            </div>
                            <Card.Body className="actionbox-body text-center">
                                
                                <Card.Text className="card-text">
                                    LP will be split proportionally to Nyan contributed<br/>
                                    Nyan Swapped: {userNyanSwapped.formatted}
                                </Card.Text>
                                <br/>
                                <Button className="pink-button claim-button actionbox-button swap-button" variant="primary" onClick={() => LGEUtil.claimNyanV2LP()}>Claim LP</Button>
                                <br/>
                                <p>OR</p>
                                <br/>
                                <Button className="pink-button claim-button actionbox-button swap-button" variant="primary" onClick={() => LGEUtil.claimNyanV2LPAndStake()}>Claim LP and Stake</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="auto"></Col>
                </Row>
            }
            <br></br>

               
        </Container>
    )

}

export default LGE;