import React, { useState, useEffect } from "react";
import { ArrowRight } from 'react-bootstrap-icons';

import nyanGif from '../assets/nyan-logo.png';

import { Image, Button, Row, Col, Container} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import StakeOrUnstakeModal from './modals/StakeOrUnstakeModal';

import nyanLogo from '../assets/logos/coins/nyan.png';
import lockedLogo from '../assets/logos/coins/lock.png';
import catnipLogo from '../assets/catnip.png';
import dnyanLogo from '../assets/logos/coins/darknyan.png';

import { Web3Util } from '../utils';

import { Balances as V2Balances, Allowance, Claim, Approve, Stake as StakingUtil } from '../utils/V2';

import { useGlobal } from 'reactn';
import { approveNipUniStaking } from "../utils/Contracts";
import ExplanationBox from './mine/ExplanationBox';

import '../style/Mine.css';

const Mine = (props) => {

    /*
    const [ nyanApproved ] = useGlobal('nyanApproved');
    const [ nipUniApproved ] = useGlobal('nipUniApproved');
    */
    const [ web3Instance ] = useGlobal('web3Instance');

    const [nyanBalance, setNyanBalance]  = useState(0);
    const [nyanLPBalance, setNyanLPBalance]  = useState(0);
    const [nyanStakedLPBalance, setNyanStakedLPBalance] = useState(0);

    const [nipBalance, setNipBalance]  = useState(0);
    const [nipLPBalance, setNipLPBalance]  = useState(0);
    const [nipStakedLPBalance, setNipStakedLPBalance] = useState(0);

    const [dNyanBalance, setDNyanBalance]  = useState(0);
    const [dNyanLPBalance, setDNyanLPBalance]  = useState(0);
    const [dNyanStakedLPBalance, setDNyanStakedLPBalance] = useState(0);
    
    const [nipLPApproved, setNipLPApproved] = useState(false);
    const [dNyanLPApproved, setDnyanLPApproved] = useState(false);

    const [dNyanRewards, setdNyanRewards] = useState(0);
    
    // modal stuff
    const [isNipLPStakeModalShown, setIsNipLPStakeModalShown] = useState(false);
    const showNipLPStakeModal = () => setIsNipLPStakeModalShown(true);
    const hideNipLPStakeModal = () => setIsNipLPStakeModalShown(false);

    const [isNipLPUnstakeModalShown, setIsNipLPUnstakeModalShown] = useState(false);
    const showNipLPUnstakeModal = () => setIsNipLPUnstakeModalShown(true);
    const hideNipLPUnstakeModal = () => setIsNipLPUnstakeModalShown(false);
    
    const [isDNyanLPStakeModalShown, setIsDNyanLPStakeModalShown] = useState(false);
    const showDNyanLPStakeModal = () => setIsDNyanLPStakeModalShown(true);
    const hideDNyanLPStakeModal = () => setIsDNyanLPStakeModalShown(false);

    const [isDNyanLPUnstakeModalShown, setIsDNyanLPUnstakeModalShown] = useState(false);
    const showDNyanLPUnstakeModal = () => setIsDNyanLPUnstakeModalShown(true);
    const hideDNyanLPUnstakeModal = () => setIsDNyanLPUnstakeModalShown(false);
    

    // Claim rewards
    const claimDarkNyanRewards = () => Claim.harvestMyDarkNyanRewards();
    

    // stake NIP/LP
    const stakeNipLP = async (amountToStake) => {
        let result = StakingUtil.stakeCatnipV2LP(amountToStake);
    }
   
    const unstakeNipLP = async(amountToUnstake) => {
        let result = StakingUtil.unstakeCatnipV2LP(amountToUnstake);
    }

    const stakeDNyanLP = async(amountToStake) => {
        let result = StakingUtil.stakeDNyanV2LP(amountToStake);
    };
    
    const unstakeDNyanLP = async(amountToStake) => {
        let result = StakingUtil.unstakeDNyanV2LP(amountToStake);
    };
    

    // Approval Nip/Uni pair
    const approveNipUniStaking = () =>  Approve.approveCatnipLPStaking();
    const approveDNyanLPStaking = () => Approve.approveDarkNyanLPStaking();

    // Constants for modal
    const stakeNipLPModalProps = {
        isModalShown: isNipLPStakeModalShown,
        hideModal: hideNipLPStakeModal,
        availableAmount: nipLPBalance,
        stakeOrUnstakeMethod: stakeNipLP,
        title: 'Stake CATNIPV2-LP',
        availableText: 'CATNIPV2-LP available'
    }

    // Constants for modal
    const unstakeNipLPModalProps = {
        isModalShown: isNipLPUnstakeModalShown,
        hideModal: hideNipLPUnstakeModal,
        availableAmount: nipStakedLPBalance,
        stakeOrUnstakeMethod: unstakeNipLP,
        title: 'Unstake CATNIPV2-LP',
        availableText: 'CATNIPV2-LP staked'
    }

    // Constants for modal
    const stakeDNyanV2LPModalProps = {
        isModalShown: isDNyanLPStakeModalShown,
        hideModal: hideDNyanLPStakeModal,
        availableAmount: dNyanLPBalance,
        stakeOrUnstakeMethod: stakeDNyanLP,
        title: 'Stake DNYANV2-LP',
        availableText: 'DNYANV2-LP available'
    }

    // Constants for modal
    const unstakeDNyanV2LPModalProps = {
        isModalShown: isDNyanLPUnstakeModalShown,
        hideModal: hideDNyanLPUnstakeModal,
        availableAmount: dNyanStakedLPBalance,
        stakeOrUnstakeMethod: unstakeDNyanLP,
        title: 'Unstake DNYANV2-LP',
        availableText: 'DNYANV2-LP staked'
    }

    // init staking page
    useEffect(() => {
        // only fires whenever requestConnectionToWallet is updated.
        async function init() {
            
            setNyanBalance(await V2Balances.getMyNyanBalance());   
            setNyanLPBalance(await V2Balances.getMyNyanLPBalance()); 
            setNyanStakedLPBalance(await V2Balances.getMyNyanStakedLPBalance());

            setNipBalance(await V2Balances.getMyCatnipBalance());
            //setNipLPBalance(await V2Balances.getMyCatnipLPBalance());
            setNipLPBalance(await V2Balances.getMyCatnipLPBalance());
            setNipStakedLPBalance(await V2Balances.getMyCatnipStakedLPBalance());
            setDNyanBalance(await V2Balances.getMyDarkNyanBalance());
            
            setDNyanLPBalance(await V2Balances.getMyDarkNyanLPBalance());
            setDNyanStakedLPBalance(await V2Balances.getMyDarkNyanStakedLPBalance());
            
            // approvals
            setNipLPApproved(await Approve.getMyCatnipStakeApproved());
            setDnyanLPApproved(await Approve.getMyDarkNyanStakeApproved());
            
            setdNyanRewards(await V2Balances.getMyDarkNyanRewards());

            /*
            setStakedNipUniAmount(await Balances.getMyStakedNipUniAmount());
            setNipUniBalance(await Balances.getMyNipUniBalance())
            setStakedNyanAmount(await Balances.getMyStakedNyanAmount())
            
            
            /* HACK */
            if(web3Instance) return false;
            let instance = await Web3Util.createInstance();
            if(!instance) console.log('cannot open modal')
        }
        init()
    }, []);

  return (

    <Container fluid>
        
            <Row className="justify-content-center align-items-center">
    
                <Col lg="auto">
                    <div styles={{backgroundImage: `url(${nyanGif})`}}></div>
                    <h1 className="title">MINE darkNYANV2</h1>
                    {/*<p className="text-center">Create the bridge to the Polkadot network!</p>
                    <p className="text-center">darkNYAN is an extension to the NYAN ecosystem that will allow NYAN voters to acquire non ERC20 assets.</p>*/}
                </Col>
            </Row>
            
            <br/>

            <Row className="justify-content-center align-items-center">
                <Col lg="auto">
                    <div styles={{backgroundImage: `url(${nyanGif})`}}></div>
                    <h3>How does it work?</h3>
                </Col>
            </Row>
            
            <br/>
            
            <Row className="justify-content-center align-items-center">

                 <ExplanationBox 
                    defaultClassNames={`explanationbox purple ${nyanStakedLPBalance.full > 0 || nyanLPBalance.full > 0 ? "" : "disabled"}`}
                    color="purple"
                    step={1}
                    cardTitle="Generate NIP"
                    cardText={[ `1. Visit <a href='https://app.uniswap.org/#/add/${process.env.REACT_APP_NYANV2_TOKEN_CONTRACT_ADDRESS}/ETH' target="_new"'>Uniswap</a><br/>`,
                                "2. Stake the LP tokens on stake page."]}
                    logoImage={nyanLogo}
                    subText1="Staked:"
                    balance={nyanStakedLPBalance}
                    coinText="NYAN"
                    
                    subText2={`(${nyanLPBalance} available)`}
                    availableBalance={nyanLPBalance}
                    
                    buttons={
                        <div>
                            <a href={`https://app.uniswap.org/#/add/${process.env.REACT_APP_NYANV2_TOKEN_CONTRACT_ADDRESS}/ETH`} target="_new">
                                <Button className="pink-button actionbox-button" variant="primary">Get LP tokens</Button>
                            </a>
                            {nyanLPBalance.full > 0 && 
                                <Link to="/app/stake">
                                    <Button className="pink-button actionbox-button paddingleft" variant="primary">Stake/Unstake</Button>
                                </Link>
                            }
                        </div>
                    }
                />
                
                <Col lg="auto">
                    <ArrowRight  className="arrow-right"/>
                </Col>

                {/* Provide the liquidity (column 2) */}
                <ExplanationBox 
                    defaultClassNames={`explanationbox yellow ${nipLPBalance.full > 0 || nipStakedLPBalance.full > 0  ? "" : "disabled"}`}
                    color="yellow"
                    step={2}
                    cardTitle="Generate DNYAN"
                    cardText={[ `1.  Visit <a href='https://app.uniswap.org/#/add/${process.env.REACT_APP_CATNIPV2_TOKEN_CONTRACT_ADDRESS}/ETH' target="_new"'>Uniswap</a><br/>`,
                                `2. Stake the LP tokens down below.`]}
                    logoImage={catnipLogo}
                    subText1="Staked:"
                    balance={nipStakedLPBalance}
                    coinText="CATNIPV2-LP"
                    availableBalance={nipLPBalance}
                    
                    buttons={
                        <div>
                             <a href={`https://app.uniswap.org/#/add/${process.env.REACT_APP_CATNIPV2_TOKEN_CONTRACT_ADDRESS}/ETH`} target="_blank">
                                <Button className="pink-button actionbox-button" variant="primary">Get CATNIPV2-LP</Button>
                            </a>

                            {!nipLPApproved ? (
                                <Button className="pink-button actionbox-button paddingleft" variant="primary" onClick={approveNipUniStaking}>Approve</Button>
                            ) : (
                                nipLPBalance.full > 0 &&
                                    <Button className="pink-button actionbox-button paddingleft" variant="primary" onClick={showNipLPStakeModal}>Stake</Button>
                            )}
                            {  nipStakedLPBalance.full > 0 &&
                                <Button className="pink-button actionbox-button paddingleft" variant="primary" onClick={showNipLPUnstakeModal}>Unstake</Button>
                            }
                        </div>
                    }
                />
                
                <Col lg="auto">
                    <ArrowRight className="arrow-right"/>
                </Col>
                

                {/* Provide the liquidity (column 3) */}
                <ExplanationBox 
                    defaultClassNames={`explanationbox blue ${dNyanRewards.full > 0 || dNyanLPBalance.full > 0 || dNyanStakedLPBalance.full > 0  ? "" : "disabled"}`}
                    color="blue"
                    step={3}
                    cardTitle="Claim DNYANV2"
                    cardText={[ `1. Claim your DNYANV2 rewards!<br/>`,
                                `2. OR <a href="https://app.uniswap.org/#/add/${process.env.REACT_APP_DNYANV2_TOKEN_CONTRACT_ADDRESS}/ETH" target="_new">add</a> LP tokens, stake them and receive an generation boost!`]}
                    logoImage={dnyanLogo}
                    subText1="Claimable:"
                    balance={dNyanRewards}
                    coinText="DNYANV2"
                    availableBalance={dNyanStakedLPBalance}
                    availableTokenText="staked"
                    available2Balance={dNyanLPBalance}
                    available2TokenText="available"
                    buttons={
                        <div>
                            {dNyanRewards.full > 0 ?
                                <Button className="pink-button actionbox-button" variant="primary" onClick={claimDarkNyanRewards}>Claim!</Button>
                                : <Button className="pink-button actionbox-button" variant="primary" onClick={null} disabled={true}>Nothing to claim!</Button>
                            }

                            {dNyanLPApproved && dNyanLPBalance.full > 0 && <Button className="pink-button actionbox-button paddingleft" variant="primary" onClick={showDNyanLPStakeModal}>Stake!</Button>}
                            {dNyanLPApproved && dNyanStakedLPBalance.full > 0 && <Button className="pink-button actionbox-button paddingleft" variant="primary" onClick={showDNyanLPUnstakeModal}>Unstake</Button>}
                            {!dNyanLPApproved && <Button className="pink-button actionbox-button paddingleft" variant="primary" onClick={approveDNyanLPStaking}>Approve!</Button>}
                            
                        </div>
                    }
                />

            </Row>
              
            <StakeOrUnstakeModal {...stakeNipLPModalProps}/>
            <StakeOrUnstakeModal {...unstakeNipLPModalProps}/>

            <StakeOrUnstakeModal {...stakeDNyanV2LPModalProps}/>
            <StakeOrUnstakeModal {...unstakeDNyanV2LPModalProps}/>
            <br/><br/>
            
      </Container>
  )
}

export default Mine;