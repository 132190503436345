import React, { useState } from "react";
import AmountField from '../fields/Amount';

import { Modal, Button, Row, Col } from 'react-bootstrap';

const StakeOrUnstakeModal = (props) => {

    const [amountInput, setAmountInput] = useState(0);
    
    const inputAmountClassNames = {
        rowClassName: 'row-stake-amount',
        colClassName: 'col-stake-amount',
        fieldClassName: 'field-stake-amount',
        maxButtonClassName: 'field-stake-max-button'
    }

    return (
        <Modal size="lg" className="modal-box" centered show={props.isModalShown} onHide={props.hideModal}>
            <Modal.Header>
                <Modal.Title className="modal-box-title">{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                
                <Row className="justify-content-left text-left max-container"> 
                    <Col>
                        {props.description}
                    </Col>
                </Row>

                <Row className="justify-content-right text-right max-container">
                    <Col><span className="darkpink-text">{props.availableAmount.formatted} {props.availableText}</span></Col>
                </Row>
                
                <AmountField {...inputAmountClassNames} maxAmount={props.availableAmount.full} amountInput={setAmountInput}></AmountField>
                
            </Modal.Body>
            <Modal.Footer className="justify-content-center align-items-center">
                <Button variant="primary brown-button" onClick={props.hideModal}>
                    Cancel
                </Button>
                <Button variant="primary pink-button" onClick={() => props.stakeOrUnstakeMethod(amountInput)} disabled={amountInput == 0}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default StakeOrUnstakeModal