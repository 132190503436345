import { Contracts } from '../index';
import { Accounts, Web3Util, Formatter} from '../../index';

/* LGE */
async function isETHLGEOver(){
  let account = await Accounts.getCurrentAccount();
  return getLGEStatus();
}

async function addNyanAndEth(nyanAmount, fee){
  let account = await Accounts.getCurrentAccount();
  return addNyanToLGE(nyanAmount, account, fee);
}

async function getLGEStatus(){
  let instance = await Contracts.getNyanV2DataContractInstance();
  let lgeStatus = await instance.methods.isETHLGEOver().call();
  return lgeStatus;
}

async function getLGEContribution(){
  let instance = await Contracts.getNyanV2DataContractInstance();
  let contribution = await instance.methods.userETHLGE(await Accounts.getCurrentAccount()).call();
  return Formatter.getRoundedAndFullBalance(contribution['nyanContributed']);
}

async function getNyanV2ETHBalance(){
  let Web3 = await Web3Util.getInstance();
  let ETH = await Web3.eth.getBalance(process.env.REACT_APP_NYANV2_TOKEN_CONTRACT_ADDRESS);
  return Formatter.getRoundedAndFullBalance(ETH.toString());
}

async function getSwappedNyanBalance(){
  //let instance = await Contracts.getNyanContractInstance();
  let instance = await Contracts.getERC20Functions(process.env.REACT_APP_NYANV2_TOKEN_CONTRACT_ADDRESS);
  let swappedNyan = await instance.methods.balanceOf(process.env.REACT_APP_NYANV2_TOKEN_CONTRACT_ADDRESS).call();
  return Formatter.getRoundedAndFullBalance(swappedNyan.toString());
}

async function addNyanToLGE(nyanAmount, account, fee){
  let instance = await Contracts.getNyanContractInstance();
  let lgeSwap = await instance.methods.addNyanAndETH(Formatter.toWei(nyanAmount.toString())).send({
      from: account,
      value: Formatter.toWei(fee.toString())
  });
  return lgeSwap;
}

async function claimNyanV2LP() {
  let account = await Accounts.getCurrentAccount();
  let instance = await Contracts.getNyanV2DataContractInstance();
  let claim = await instance.methods.claimETHLP().send({
    from: account
  });
  return claim;
}

async function claimNyanV2LPAndStake(){
  let account = await Accounts.getCurrentAccount();
  let instance = await Contracts.getNyanV2DataContractInstance();
  
  let claim = await instance.methods.claimETHLPAndStake().send({
    from: account
  });
  return claim;
}

export {
  isETHLGEOver,
  addNyanAndEth,
  getLGEStatus,
  getLGEContribution,
  getNyanV2ETHBalance,
  getSwappedNyanBalance,
  addNyanToLGE,
  claimNyanV2LP,
  claimNyanV2LPAndStake
}