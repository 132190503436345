/** All interations with the contracts */
import { Web3Util } from '../../index';

import ERC20 from "../../../contracts/ERC20.json";
import ERC721 from "../../../contracts/ERC721.json";
import ERC721Data from "../../../contracts/ERC721DataLayout.json";

import Governance from "../../../contracts/Governance.json";
import GovernanceVotingV2Data from "../../../contracts/GovernanceDataLayout.json"; // point to ERC20 layout


/** VOTING CONTRACTS */
async function getGovernanceVotingContractInstance(){
  if(!window.GVContract) {
      const instance = await Web3Util.getInstance();
      window.GVContract = new instance.eth.Contract(
          Governance.abi,
          process.env.REACT_APP_GOVERNANCE_CONTRACT_ADDRESS
      );
  }
  return window.GVContract
}

async function getGovernanceVotingDataContractInstance(){
  if(!window.GVDataContract) {
      const instance = await Web3Util.getInstance();
      window.GVDataContract = new instance.eth.Contract(
          GovernanceVotingV2Data.abi,
          process.env.REACT_APP_GOVERNANCE_CONTRACT_ADDRESS
      );
  }
  return window.GVDataContract
}

export {
  getGovernanceVotingContractInstance,
  getGovernanceVotingDataContractInstance
}