import React, { useState } from "react";
import { Container, Row, Col, Modal, Button, Image } from 'react-bootstrap';
import Catlogo from '../assets/logos/cat-logo.png';
import { Link } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { useHistory } from "react-router-dom";
import { Web3Util } from '../utils/index';

import '../style/Landing.css';

const Landing = (props) => {

  const [ web3Instance ] = useGlobal('web3Instance');
  const [showMissingWeb3Modal, setShowMissingWeb3Modal]  = useState(false);
  const history = useHistory(); 

  const connect = async() => {
    
    if(web3Instance) {
      history.replace('/app');
      return false;
    } else {
      let instance = await Web3Util.createInstance();
      if(instance){
        history.replace('/app');
        return false;
      } else {
        console.log('Cannot open app');
      }
    }
    return false;
  }


  return (

    <Container fluid>
      <br/><br/>
      <Row className="justify-content-center align-items-center">
          <Col lg="auto" className=""></Col>
          <Col lg={6}>
              <div className="text-center header-image">
                <Image src={Catlogo} fluid/>
              </div>
              <h1 className="title">NYAN.FINANCE</h1>
              <p className="text-center">Community Governed Hedge Fund</p>
          </Col>
          <Col lg="auto" className=""></Col>
      </Row>
      
      <br/><br/><br/>

      <Row className="justify-content-center align-items-center">
          <Col lg="auto" xs={false}></Col>
          <Col lg={4} sm={5} xs={12} md={5} xl={4} className="text-right order-1">
            <Button className="btn-lg pink-button" 
                    onClick={connect}>
                    Launch app
            </Button>
          </Col>
          <Col lg={4} sm={5} xs={12} md={5} xl={4} className="order-2">
            <a target="_blank" rel="noopener noreferrer" href="https://www.learnnyan.finance/start-here/intro-to-usdnyan-+-usdnip">
              <Button className="btn-lg pink-button">Read the docs</Button>
            </a>
          </Col>
          <Col lg="auto" xs={false}></Col>
      </Row>
  
      <Modal className="modal-box" show={showMissingWeb3Modal}>
        <Modal.Header>
          <Modal.Title className="modal-box-title">Connection issue</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>We were unable to connect to a valid wallet. Please make sure you have a Web3 compatible wallet loaded,
              and you have approved the request to connect.<br/>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary pink-button" onClick={() => setShowMissingWeb3Modal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </Container>
  );
}

export default Landing;

/*
export default class Landing extends Component {

  closeMissingWeb3Modal = () => {
    this.setState({
      showMissingWeb3Modal: false
    });
  }
  
  state = {
    showMissingWeb3Modal: false
  };

   
  componentDidMount = async () => {
    document.title = "Nyan.finance";
  };

  /*
  launchApp = async () => {
    // check for web3 instance. If so, let it connect.
    //let isPresent = await Web3.Example();
    
    //console.log(isPresent)
    //console.log(isConnected)
    /*
    if(!isPresent){
      console.log('missing')
      /*
      // show error
      this.setState({
        showMissingWeb3Modal: true
      })
      
    }
    else {
      // check if connected
      let accounts = Web3.getAccounts();
      this.props.history.push('/app')
    }
    
  }
  

  render() {
    // if (!this.state.loaded) {
    //   return <div>Loading Web3, accounts, and contract...</div>;
    // }
    
  }
}
*/
