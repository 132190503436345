import React, { useState, useEffect, useRef} from "react";
import { Container, Row, Col, Badge, Table} from 'react-bootstrap';

import FunctionRows from './FunctionRows';
import AddApprovalBox from './AddApprovalBox';

const BidDescription = (props) => {
  
  //const [description, setDescription] = useState('');
  //const ref = useRef();

  useEffect(() => {
      // only fires whenever requestConnectionToWallet is updated.
      console.log(props.bid);
      //const description = 'On the CHAIN_NAME chain, call the method METHOD_NAME with the following parameters: [ADDRESSES], [STRINGS], [ADDRESSES] '
      //setDescription(description)
  },[])

  const functionOverlayProps = {
    interface: props.bid.interface,
    functions: props.bid.functions
  }

  // modal stuff
  const [isApprovalModalShown, setIsApprovalModalShown] = useState(false);
  const showApprovalModal = () => setIsApprovalModalShown(true);
  const hideApprovalModal = () => setIsApprovalModalShown(false);

  const approvalModalProps = {
    isModalShown: isApprovalModalShown,
    hideModal: hideApprovalModal
}


  return (
    <Container fluid>
        <br/>

        <Row>
          <Col><h3>Interface: <Badge variant="primary">{props.bid.interface}</Badge></h3></Col>
        </Row>

        <Row>
          <Col>
              <span>A function is what will be executed on the chain you selected. <br/>
              Choose your function(s) and add the information needed to construct your bid.</span>
              As a start, why don't you <a className="pink-link" onClick={showApprovalModal}>add an approval function</a> first?
          </Col>
        </Row>

        <br/>

        <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Function</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>swapETH</td>
                <td>Edit | Remove </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              
            </tbody>
        </Table>

        <br/>
        
        
        <Row>
          <Col><FunctionRows {...functionOverlayProps}/></Col>
        </Row>

        <AddApprovalBox {...approvalModalProps}/>
        
        {/*
        <Row>
          <Col>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col lg={3} className="text-left" className="bid-tab-border">
                <Nav variant="pills" className="flex-column proposal-tab-item">
                  <Nav.Item>
                    <Nav.Link eventKey="summary">Function</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="addresses">Addresses</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="integers">Integers</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="strings">Strings</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="bytes">Bytes</Nav.Link>
                  </Nav.Item>
                  
                </Nav>
              </Col>
              <Col className="bid-description-border">
                <Tab.Content className="text-left mb-2">
                  <Tab.Pane eventKey="summary" className="bid-content">
                    <Row>
                        <Col>A function is what will be executed on the chain you selected. <br/>
                            Choose your function and add addresses/strings/integers/bytes in the panel to the left to construct your bid.
                        </Col>
                    </Row>
                    <Row>
                      <Col><FunctionOverlay {...functionOverlayProps}/></Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="addresses" className="bid-content">
                    <Row>
                        <Col>Addresses are used to either send or receive funds from.<br/>
                             Input the addresses and press TAB to add another.
                        </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Typeahead
                            allowNew
                            id="custom-selections-example"
                            multiple
                            newSelectionPrefix="Add a new item: "
                            options={[]}
                            placeholder="Type address..."
                            ref={ref}
                          />
                          <ButtonToolbar style={{ marginTop: '10px' }}>
                            <Button
                              onClick={() => ref.current.clear()}
                              variant="outline-secondary">
                              Clear
                            </Button>
                          </ButtonToolbar>
                      </Col>
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
        
          </Tab.Container>
          </Col>
          

        </Row>
        */}
      </Container>
  )
  
}
export default BidDescription