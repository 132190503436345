import { Accounts, Contracts } from "./index"

const Web3Utils = require('web3-utils');

/**
 * amount of NYAN to withdraw from the staked contract.
 * @param {String} amountToWithdraw 
 */
const stakeNyan = async (amountToStake) => {
    let account = await Accounts.getCurrentAccount();
    let instance = await Contracts.getCatnipContractInstance();

    try {
        let stakeRes = await instance.methods.stake(Web3Utils.toWei(amountToStake.toString())).send({
            from: account
        });
    
        if (stakeRes["status"]) {
            return {
                success: true
            }
        } else {
            return {
                success: false
            }
        }
    } catch (error) {
        console.log(error);
    }
  }



  const stakeCatnipUni = async(amountToStake) => {
    let account = await Accounts.getCurrentAccount();
    let instance = await Contracts.getDarkNyanContractInstance();

    try {
        let stakeRes = await instance.methods.stakeCatnipUni(Web3Utils.toWei(amountToStake.toString())).send({
            from: account
        });
    
        if (stakeRes["status"]) {
            return {
                success: true
            }
        } else {
            return {
                success: false
            }
        }
    } catch (error) {
        console.log(error);
    }
  }
// Export each function
export {
    stakeNyan,
    stakeCatnipUni
 };
