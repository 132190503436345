import React, { useState, useRef, useEffect } from "react";
import { Form, Col, Row, Button } from "react-bootstrap";
import RangeSlider from 'react-bootstrap-range-slider';

const AmountField = (props) => {

    const [ amount, setAmount ] = useState(0);
    const [ finalAmount, setFinalAmount ] = React.useState(null);

    const setMaxAmount = () => {
      setFinalAmount(33000)
      setAmount(33000);
    }

    return (
      <Form>
        <Form.Group as={Row}>
          <Col lg="8" xs="6">
            <RangeSlider
              value={amount}
              min={1}
              max={33000}
              onChange={e => setAmount(e.target.value)}
              onAfterChange={e => setFinalAmount(e.target.value)}
            />
          </Col>
          <Col lg="2" xs="3">
            <Form.Control onChange={() => {}} value={amount}/>
          </Col>
          <Col lg="1" xs="3">
            <Button size="sm" className="pink-button" onClick={setMaxAmount}>Max</Button>
          </Col>
        </Form.Group>
      </Form>
    )
}

export default AmountField