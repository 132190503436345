import React, { useState } from "react";
import { Container, Row, Col, Modal, Button, Image } from 'react-bootstrap';
import Catlogo from '../../assets/logos/cat-logo.png';

import { useGlobal } from 'reactn';
import { useHistory } from "react-router-dom";
import { Web3Util } from '../../utils/index';

import ProposalCanvas from './components/Canvas';

const ProposalBuilderPage = (props) => {


    return (
         <Container fluid>
            
            <Row className="justify-content-center align-items-center">
                <Col lg="auto" className=""></Col>
                <Col lg={6}>
                    <h1 className="title">The Cat's Cradle</h1>
                    <p className="text-center">Welcome to the proposal builder</p>
                </Col>
                <Col lg="auto" className=""></Col>
            </Row>

            <Row className="justify-content-center align-items-center">
                <Col xl lg md sm xs={1}></Col>
                <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                    <ProposalCanvas/>
                </Col>
                <Col xl lg md sm xs={1}></Col>
            </Row>

      </Container>
    )
}

export default ProposalBuilderPage