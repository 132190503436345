import { Formatter, Accounts, Web3Util } from '../index';
import { Contracts } from './index';

/***
 * Convenience methods for current account
 */
async function getMyNyanBalance(){
  let account = await Accounts.getCurrentAccount();
  return getNyanBalance(account);
}

async function getMyCatnipBalance(){
  let account = await Accounts.getCurrentAccount();
  return getCatnipBalance(account);
}

async function getMyDarkNyanBalance(){
  let account = await Accounts.getCurrentAccount();
  return getDarkNyanBalance(account);
}

async function getMyNyanLPBalance(){
  let account = await Accounts.getCurrentAccount();
  return getNyanLPBalance(account);
}

async function getMyCatnipLPBalance(){
  let account = await Accounts.getCurrentAccount();
  return getCatnipLPBalance(account);
}

async function getMyDarkNyanLPBalance(){
  let account = await Accounts.getCurrentAccount();
  return getDarkNyanLPBalance(account);
}

async function getMyNyanStakedLPBalance(){
  let account = await Accounts.getCurrentAccount();
  return getNyanStakedLPBalance(account);
}

async function getMyCatnipStakedLPBalance(){
  let account = await Accounts.getCurrentAccount();
  return getCatnipStakedLPBalance(account);
}

async function getMyDarkNyanStakedLPBalance(){
  let account = await Accounts.getCurrentAccount();
  return getDarkNyanStakedLPBalance(account);
}

// shorthand function
async function getMyCatnipRewards(){
  let account = await Accounts.getCurrentAccount();
  return getCatnipRewards(account);
}

async function getMyDarkNyanRewards(){
  let account = await Accounts.getCurrentAccount();
  
  return getDarkNyanRewards(account);

}

/**
 * Check ETH balance
 */
async function getEthBalance(){
  let account = await Accounts.getCurrentAccount();
  let web3Instance = await Web3Util.getInstance();
  if(!web3Instance){
      // do nothing
      return null
  }
  return Formatter.getRoundedAndFullBalance(await web3Instance.eth.getBalance(account)); 
}

/**
* Function to fetch the NyanV2 balance for a given account
* @param {string} account 
*/
async function getNyanBalance(account){
  let instance = await Contracts.getERC20Functions(process.env.REACT_APP_NYANV2_TOKEN_CONTRACT_ADDRESS);
  let nyanV2Balance = await instance.methods.balanceOf(account).call();
  return Formatter.getRoundedAndFullBalance(nyanV2Balance);
}

/**
* Function to fetch the CatnipV2 balance for a given account
* @param {string} account 
*/
async function getCatnipBalance(account){
  let instance = await Contracts.getERC20Functions(process.env.REACT_APP_CATNIPV2_TOKEN_CONTRACT_ADDRESS);
  
  let catnipV2Balance = await instance.methods.balanceOf(account).call();
  return Formatter.getRoundedAndFullBalance(catnipV2Balance);
}

/**
* Function to fetch the DarkNyanV2 balance for a given account
* @param {string} account 
*/
async function getDarkNyanBalance(account){
  let instance = await Contracts.getERC20Functions(process.env.REACT_APP_DNYANV2_TOKEN_CONTRACT_ADDRESS);
  let darkNyanV2Balance = await instance.methods.balanceOf(account).call();
  return Formatter.getRoundedAndFullBalance(darkNyanV2Balance);
}

/**
* Function to fetch the NyanV2LP balance for a given account
* @param {string} account 
*/
async function getNyanLPBalance(account){
  let instance = await Contracts.getERC20Functions(process.env.REACT_APP_NYAN_UNI_TOKEN_CONTRACT_ADDRESS); 
  let nyanV2LPBalance = await instance.methods.balanceOf(account).call();
  return Formatter.getRoundedAndFullBalance(nyanV2LPBalance);
}

/**
* Function to fetch the NyanV2LP balance for a given account
* @param {string} account 
*/
async function getCatnipLPBalance(account){
  let instance = await Contracts.getERC20Functions(process.env.REACT_APP_CATNIPUNI_TOKEN_CONTRACT_ADDRESS); 
  let catnipV2LPBalance = await instance.methods.balanceOf(account).call();
  return Formatter.getRoundedAndFullBalance(catnipV2LPBalance);
}

/**
* Function to fetch the NyanV2LP balance for a given account
* @param {string} account
*/
async function getDarkNyanLPBalance(account){
  let instance = await Contracts.getERC20Functions(process.env.REACT_APP_DARKNYAN_UNI_TOKEN_CONTRACT_ADDRESS); 
  let darkNyanV2LPBalance = await instance.methods.balanceOf(account).call();
  return Formatter.getRoundedAndFullBalance(darkNyanV2LPBalance);
}

// get staked amounts
async function getNyanStakedLPBalance(account){
  let instance = await Contracts.getNyanV2DataContractInstance();
  let staked = await instance.methods.userStake(account).call();
  //stakedNyanV2LP   uint256 
  //stakedDNyanV2LP   uint256 
  //nyanV2Rewards   uint256 
  //lastBlockChecked   uint256 
  //blockStaked   uint256
  return Formatter.getRoundedAndFullBalance(staked[0]);
}

//get staked amount
async function getCatnipStakedLPBalance(account){
  let instance = await Contracts.getDarkNyanDataContractInstance();
  let staked = await instance.methods.stakedBalances(account).call();
  //lastBlockChecked
  //rewards
  //catnipV2LPStaked
  //dNyanV2LPStaked  
  return Formatter.getRoundedAndFullBalance(staked[2]);
}

// get rewards
async function getCatnipRewards(account){
  let instance = await Contracts.getCatnipV2DataContractInstance();
  let staked = await instance.methods.myRewardsBalance(account).call();
  return Formatter.getRoundedAndFullBalance(staked);
}

async function getNyanRewards() {
  let account = await Accounts.getCurrentAccount();
  let instance = await Contracts.getNyanV2DataContractInstance()

  let rewards = await instance.methods.viewNyanRewards(account).call(); 
  
  return Formatter.getRoundedAndFullBalance(rewards);
}

async function getDarkNyanRewards(account){
  let instance = await Contracts.getDarkNyanContractInstance();
  let rewards = await instance.methods.myRewardsBalance(account).call();
  return Formatter.getRoundedAndFullBalance(rewards, 10);
}

/** VOTING */
async function getMyTotalVotingTokens() {
  let account = await Accounts.getCurrentAccount();
  return getTotalVotingTokens(account);
}

// Only Staked LP tokens are eligible for voting
async function getTotalVotingTokens(account){
  let stakedLP = await getNyanStakedLPBalance(account)
  return Formatter.getRoundedAndFullBalanceForNumber(stakedLP.full);
}

/* Used? */
async function getDarkNyanStakedLPBalance(account){
  let instance = await Contracts.getNyanV2DataContractInstance();
  let staked = await instance.methods.userStake(account).call();
  //lastBlockChecked
  //rewards
  //catnipV2LPStaked
  //dNyanV2LPStaked  
  return Formatter.getRoundedAndFullBalance(staked.stakedDNyanV2LP);
}

export {
  getMyNyanBalance,
  getMyCatnipBalance,
  getMyDarkNyanBalance,
  getMyNyanLPBalance,
  getMyCatnipLPBalance,
  getMyDarkNyanLPBalance,
  getMyNyanStakedLPBalance,
  getMyCatnipStakedLPBalance,
  getMyDarkNyanStakedLPBalance,
  getEthBalance,
  getMyCatnipRewards,
  getNyanRewards,
  getMyDarkNyanRewards,
  getMyTotalVotingTokens
}
