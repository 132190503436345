import { Contracts, Accounts } from '../../index';
import { Contracts as V2Contracts } from '../index';


async function getMyNyanSwapAllowed() {
  const account = await Accounts.getCurrentAccount();
  const amount = await getNyanSwapAllowance(account);
  return amount > 0;
}

async function getMyCatnipSwapAllowed() {
  const account = await Accounts.getCurrentAccount();
  const amount = await getCatnipSwapAllowance(account);
  return amount > 0;
}
async function getMyDarkNyanSwapAllowed() {
  const account = await Accounts.getCurrentAccount();
  const amount = await getDNyanSwapAllowance(account);
  return amount > 0;
}

async function getMyNyanStakeAllowed() {
  const account = await Accounts.getCurrentAccount();
  const amount = await getNyanStakeAllowance(account);
  return amount > 0;
}

async function getMyCatnipStakeAllowed() {
  const account = await Accounts.getCurrentAccount();
  const amount = await getCatnipStakeAllowance(account);
  return amount > 0;
}
async function getMyDarkNyanStakeAllowed() {
  const account = await Accounts.getCurrentAccount();
  const amount = await getDarkNyanStakeAllowance(account);
  return amount > 0;
}

 /* Swap NYAN V2 Allowance */
async function getNyanSwapAllowance(account){
  let instance = await V2Contracts.getERC20Functions(process.env.REACT_APP_NYAN_TOKEN_CONTRACT_ADDRESS); // do the check on the V1 contract and match it to the V2 contract
  console.log(account);
  console.log(process.env.REACT_APP_NYANV2_TOKEN_CONTRACT_ADDRESS)
  let nyanAllowance = await instance.methods.allowance(account, process.env.REACT_APP_NYANV2_TOKEN_CONTRACT_ADDRESS).call();
  return nyanAllowance;
}

/* Swap NYAN V2 Allowance */
async function getCatnipSwapAllowance(account){
  let instance = await V2Contracts.getERC20Functions(process.env.REACT_APP_CATNIP_TOKEN_CONTRACT_ADDRESS); // do the check on the V1 contract and match it to the V2 contract
  let nipAllowance = await instance.methods.allowance(account, process.env.REACT_APP_CATNIPV2_TOKEN_CONTRACT_ADDRESS).call();
  console.log(nipAllowance);
  return nipAllowance;
}

/* Swap NYAN V2 Allowance */
async function getDNyanSwapAllowance(account){
  let instance = await V2Contracts.getERC20Functions(process.env.REACT_APP_DARKNYAN_TOKEN_CONTRACT_ADDRESS); // do the check on the V1 contract and match it to the V2 contract
  let dNyanAllowance = await instance.methods.allowance(account, process.env.REACT_APP_DNYANV2_TOKEN_CONTRACT_ADDRESS).call();
  
  return dNyanAllowance;
}

/** Stake allowance */
async function getNyanStakeAllowance(account){

  let instance = await V2Contracts.getERC20Functions(process.env.REACT_APP_NYAN_UNI_TOKEN_CONTRACT_ADDRESS); // do the check on the V1 contract and match it to the V2 contract
  let nyanAllowance = await instance.methods.allowance(account, process.env.REACT_APP_NYANV2_TOKEN_CONTRACT_ADDRESS).call();
  console.log(nyanAllowance);
  return nyanAllowance;
}

/** Stake allowance */
async function getCatnipStakeAllowance(account){
  let instance = await V2Contracts.getERC20Functions(process.env.REACT_APP_CATNIPUNI_TOKEN_CONTRACT_ADDRESS); // do the check on the V1 contract and match it to the V2 contract
  let nipAllowance = await instance.methods.allowance(account, process.env.REACT_APP_DNYANV2_TOKEN_CONTRACT_ADDRESS ).call();
  console.log(nipAllowance);
  return nipAllowance;
}

/** Stake allowance */
async function getDarkNyanStakeAllowance(account){

  let instance = await V2Contracts.getERC20Functions(process.env.REACT_APP_DARKNYAN_UNI_TOKEN_CONTRACT_ADDRESS); // do the check on the V1 contract and match it to the V2 contract
  let nyanAllowance = await instance.methods.allowance(account, process.env.REACT_APP_NYANV2_TOKEN_CONTRACT_ADDRESS).call();
  console.log(nyanAllowance);
  return nyanAllowance;
}


export {
  getNyanSwapAllowance,
  getCatnipSwapAllowance,
  getDNyanSwapAllowance,
  getNyanStakeAllowance,
  getCatnipStakeAllowance,
  getDarkNyanStakeAllowance,
  getMyCatnipStakeAllowed,
  getMyDarkNyanStakeAllowed,
  getMyNyanStakeAllowed,
  getMyNyanSwapAllowed,
  getMyCatnipSwapAllowed,
  getMyDarkNyanSwapAllowed
}
  