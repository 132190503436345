import React, { useState, useEffect, useContext  } from "react";
import Accordion from 'react-bootstrap/Accordion'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

import { Web3Util, Balances, Supply, Formatter } from '../utils';
import { Balances as V2Balances, Claim, Rewards, NFT } from '../utils/V2';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCrown, faRocket } from '@fortawesome/pro-solid-svg-icons';


import ReadOnlyAmountField from './fields/ReadOnlyAmountField';
import ReadOnlyHeaderAmountField from './fields/ReadOnlyHeaderAmountField';

import { useGlobal } from 'reactn';

import '../style/Pool.css';
import { stakeDNyanV2LP } from "../utils/V2/Stake";

const Pool = (props) => {

  const [nyanBalance, setNyanBalance]  = useState({});
  const [nipBalance, setNipBalance] = useState({});
  const [darkNyanBalance, setDarkNyanBalance] = useState({});

  const [nyanV2Balance, setNyanV2Balance]  = useState({});
  const [nipV2Balance, setNipV2Balance] = useState({});
  const [darkNyanV2Balance, setDarkNyanV2Balance] = useState({});

  const [ web3Instance ] = useGlobal('web3Instance');
  const [liquidityButtonsHidden, setLiquidityButtonsHidden] = useState([false,false]);

  const [nyanSupply, setNyanSupply] = useState({});
  const [nipSupply, setNipSupply] = useState({});
  const [darkNyanSupply, setDarkNyanSupply] = useState({});

  //const [stakedNyanAmount, setStakedNyanAmount]  = useState(0); // staked NYANV2
  const [stakedNipLPAmount, setStakedNipLPAmount] = useState({}); // staked NYANV1
  const [stakedDNyanLPAmount, setStakedDNyanLPAmount]  = useState(0); // staked NYANV2
  
  const [calculatedNIPGeneration, setCalculatedNIPGeneration] = useState(0);
  const [calculatedNIPUniGeneration, setCalculatedNIPUniGeneration] = useState(0);
  
  const [dNyanRewards, setdNyanRewards] = useState({});
  const [catnipRewards, setCatnipRewards]  = useState({});
  const [nyanRewards, setNyanRewards] = useState({});

  // V2 balance stuff
  const [nyanLPBalance, setNyanLPBalance]  = useState({}); // nyan v2 balance
  const [stakedNyanLPAmount, setStakedNyanLPAmount]  = useState({});
  const [catnipV2Rewards, setCatnipV2Rewards] = useState({});
  

  // NFT stuff
  const [nftDetails, setNFTDetails] = useState(false);


  // last block staked
  const [lastBlockNyanStaked, setLastBLockNyanStaked] = useState(0);
  const [lastBlockCatnipStaked, setLastBLockCatnipStaked] = useState(0);
  

  const LiquidityToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionToggle(eventKey, () => {
      liquidityButtonsHidden[eventKey] = !liquidityButtonsHidden[eventKey];
      setLiquidityButtonsHidden(liquidityButtonsHidden);
    })
  
    return (
      <button
        type="button"
        variant="link"
        className="liquidity-more-info-button"
        onClick={decoratedOnClick}
      >
        {!liquidityButtonsHidden[eventKey] ? "More info" : "Close"}
      </button>
    );
  }

  const harvestNyanRewards = () => {
    let result = Claim.harvestMyNyanRewards();
  }

  // init staking page
  useEffect(() => {
    // only fires whenever requestConnectionToWallet is updated.
    async function init() {
        setNyanBalance(await Balances.getMyNyanBalance());
        setNipBalance(await Balances.getMyCatnipBalance());
        setDarkNyanBalance(await Balances.getMyDarkNyanBalance());
        
        setNyanV2Balance(await V2Balances.getMyNyanBalance());
        setNipV2Balance(await V2Balances.getMyCatnipBalance());
        setDarkNyanV2Balance(await V2Balances.getMyDarkNyanBalance());
        
        setNyanSupply(await Supply.getNyanSupply());
        setNipSupply(await Supply.getCatnipSupply());
        setDarkNyanSupply(await Supply.getDarkNyanSupply());

        //setStakedNyanAmount(await Balances.getMyStakedNyanAmount())
        //setStakedNipUniAmount(await Balances.getMyStakedNipUniAmount());


        setCatnipRewards(await Balances.getMyCatnipRewards())
        setdNyanRewards(await V2Balances.getMyDarkNyanRewards());

        // v2 stuff
        setNyanLPBalance(await V2Balances.getMyNyanLPBalance());
        setStakedNyanLPAmount(await V2Balances.getMyNyanStakedLPBalance()) //currently lp'ed nyan
        setStakedNipLPAmount(await V2Balances.getMyCatnipStakedLPBalance());
        setStakedDNyanLPAmount(await V2Balances.getMyDarkNyanStakedLPBalance());
        
        setCatnipV2Rewards(await V2Balances.getMyCatnipRewards());
        
        setNyanRewards(await V2Balances.getNyanRewards());

        setLastBLockCatnipStaked(await Rewards.getLastBlockCatnipStaked());
        setLastBLockNyanStaked(await Rewards.getLastBlockNyanStaked());

        setCalculatedNIPGeneration(await Rewards.getMyCatnipGeneration());
        setCalculatedNIPUniGeneration(await Rewards.getMyDarkNyanGeneration());
      
        setNFTDetails(await NFT.getMyNFTDetails());
        console.log(await Rewards.getMyCatnipGeneration());

        /* HACK */
        if(web3Instance) return false;
        let instance = await Web3Util.createInstance();
        if(!instance) console.log('cannot open modal')
    }
    init()
  }, []);

  return (
    <Container fluid>
            <br/>
            
            <Row className="justify-content-center align-items-center">

                <Col lg={2}></Col>
                <Col lg={8} className="explanationbox liquiditybox blue">
                    <Row>
                        <Col lg="auto">
                            <h4 className="card-title">Liquidity provider & Staking rewards</h4>
                            <div className="card-text">
                              
                                <span className="card-subtitle">1. Stake NYANV2-LP</span> <br/>
                                Staking  NYANV2-LP provides you with CATNIPV2 at a rate of <b>0.06535</b> per  NYANV2-LP staked per 24 hours.<br/>
                                20% will be deducted and goes to the hedgefund, leaving <b>0.05200</b>.<br/>

                                <span className="card-subtitle">2. Provide CATNIPV2 Liquidity</span><br/>
                                Providing liquidity on the CATNIPV2/ETH pair generates DNYAN at a daily rate of <b>0.001733</b> per CATNIPV2-LP Token
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col lg={2}></Col>
            </Row>

            <br/><br/>
            
            {/*
            <Row className="justify-content-center align-items-center text-center">

                <Col lg={2} sm={false}>
                </Col>
                <Col lg={8} sm={12} className="explanationbox liquiditybox nftbox text-center">
                    <Row className="inner-row-rewards">
                        <Col lg={2} className="justify-content-center align-items-center">
                        </Col>
                        <Col lg={8} className="daily-rewards">
                            <h4 className="card-title">THANK YOU FOR YOUR SUPPORT</h4>
                            <div className="card-text">
                                <h1 className="card-subtitle">{nyanRewards.formatted}</h1>
                            </div>
                        </Col>
                        <Col lg={2}>
                          </Col>
                    </Row>
                </Col>
                <Col lg={2}>

                </Col>
            </Row>

            <br/><br/>
            */}

            <Row className="justify-content-center align-items-center text-center">

                <Col lg={2} sm={false}>
                </Col>
                <Col lg={8} sm={12} className="explanationbox liquiditybox gold text-center">
                    <Row className="inner-row-rewards">
                        <Col lg={2} className="justify-content-center align-items-center">
                          <FontAwesomeIcon icon={faCrown} size="5x" className="crown-icon left-crown"/>
                        </Col>
                        <Col lg={8} className="daily-rewards">
                            <h4 className="card-title">Total NYANV2 rewarded:</h4>
                            <div className="card-text">
                                <h1 className="card-subtitle">{nyanRewards.formatted}</h1>
                            </div>
                        </Col>
                        <Col lg={2}>
                          <FontAwesomeIcon icon={faCrown} size="5x" className="crown-icon right-crown"/>
                        </Col>
                    </Row>
                    <Row className="inner-button-row-rewards">
                        <Col className="daily-rewards-claim-row">
                          { nyanRewards.full > 0 && <Button size="lg" className="claim-button" onClick={harvestNyanRewards}>Harvest!</Button> }
                        </Col>
                    </Row>
                </Col>
                <Col lg={2}>

                </Col>
            </Row>
            
            <br/><br/>

            <Row className="justify-content-center align-items-center">
                <Col lg="auto"><h4 className="balances-title">Your Balances</h4></Col>
            </Row>
            <br/>

             {/** BALANCES  */}
             <Row className="justify-content-center align-items-center">

                <Col lg="auto"></Col>
                <Col lg={3} className="text-center">
                  <Card className="actionbox text-center">
                      <Card.Body className="actionbox-body text-center">
                          <Card.Title>
                            <ReadOnlyHeaderAmountField formattedAmount={nyanBalance.formatted} fullAmount={nyanBalance.full}/>
                          </Card.Title>
                          <Card.Text className="card-text">
                              NYAN V1<br/>
                              <a href="/app/swap">Swap</a>
                          </Card.Text>
                      </Card.Body>
                  </Card>
                </Col>
                <Col lg={3} className="text-center">
                  <Card className="actionbox text-center">
                      <Card.Body className="actionbox-body text-center">
                          <Card.Title>
                            <ReadOnlyHeaderAmountField formattedAmount={nipBalance.formatted} fullAmount={nipBalance.full}/>
                          </Card.Title>
                          <Card.Text className="card-text">
                              NIP V1<br/>
                              <a href="/app/swap">Swap</a>
                          </Card.Text>
                      </Card.Body>
                  </Card>
                </Col>
                <Col lg={3} className="text-center">
                  <Card className="actionbox text-center">
                      <Card.Body className="actionbox-body text-center">
                          <Card.Title>
                            <ReadOnlyHeaderAmountField formattedAmount={darkNyanBalance.formatted} fullAmount={darkNyanBalance.full}/>
                          </Card.Title>
                          <Card.Text className="card-text">
                              DarkNYAN V1<br/>
                              <a href="/app/swap">Swap</a>
                          </Card.Text>
                      </Card.Body>
                  </Card>
                </Col>
                <Col lg="auto"></Col>
            </Row>
            
            <br/><br/>

            {/** BALANCES  */}
            <Row className="justify-content-center align-items-center">

                <Col lg="auto"></Col>
                <Col lg={3} className="text-center">
                  <Card className="actionbox text-center">
                      <Card.Body className="actionbox-body text-center">
                         
                          <Card.Title>
                            <ReadOnlyHeaderAmountField formattedAmount={nyanV2Balance.formatted} fullAmount={nyanV2Balance.full}/>
                          </Card.Title>

                          <Card.Text className="card-text">
                              NYAN V2
                          </Card.Text>
                      </Card.Body>
                  </Card>
                </Col>
                <Col lg={3} className="text-center">
                  <Card className="actionbox text-center">
                      <Card.Body className="actionbox-body text-center">
                          
                          <Card.Title>
                            <ReadOnlyHeaderAmountField formattedAmount={nipV2Balance.formatted} fullAmount={nipV2Balance.full}/>
                          </Card.Title>
                          <Card.Text className="card-text">
                              NIP V2
                          </Card.Text>
                      </Card.Body>
                  </Card>
                </Col>
                <Col lg={3} className="text-center">
                  <Card className="actionbox text-center">
                      <Card.Body className="actionbox-body text-center">
                          <Card.Title>
                            <ReadOnlyHeaderAmountField formattedAmount={darkNyanV2Balance.formatted} fullAmount={darkNyanV2Balance.full}/>
                          </Card.Title>
                          <Card.Text className="card-text">
                              DarkNYAN V2
                          </Card.Text>
                      </Card.Body>
                  </Card>
                </Col>
                <Col lg="auto"></Col>
            </Row>
      
            <br/><br/>

            {/*
            <Row className="justify-content-center align-items-center">
                <Col lg="auto"><h4 className="balances-title">Supply details</h4></Col>
            </Row>

            {/** POOLING  
            <Row className="justify-content-center align-items-center">

                <Col lg="auto"></Col>
                <Col lg={3} className="text-center">
                  <Card className="actionbox text-center">
                      <Card.Body className="actionbox-body text-center">
                          <Card.Title className="card-title">{nyanSupply}</Card.Title>
                          <Card.Text className="card-text">
                              NYAN V1
                          </Card.Text>
                      </Card.Body>
                  </Card>
                </Col>
                <Col lg={3} className="text-center">
                  <Card className="actionbox text-center">
                      <Card.Body className="actionbox-body text-center">
                          <Card.Title className="card-title">{nipSupply}</Card.Title>
                          <Card.Text className="card-text">
                              NIP V1
                          </Card.Text>
                      </Card.Body>
                  </Card>
                </Col>
                <Col lg={3} className="text-center">
                  <Card className="actionbox text-center">
                      <Card.Body className="actionbox-body text-center">
                          <Card.Title className="card-title">{darkNyanSupply}</Card.Title>
                          <Card.Text className="card-text">
                              DNYAN V1
                          </Card.Text>
                      </Card.Body>
                  </Card>
                </Col>
                <Col lg="auto"></Col>
            </Row>
            */}
            <br/><br/>

            <Row className="justify-content-center align-items-center">
                <Col lg="auto"><h4 className="balances-title">Your Liquidity</h4></Col>
            </Row>
            
            {/** POOLING  */}
            <Row className="justify-content-center align-items-center">

                <Col lg="auto"></Col>
                <Col lg={9}>
                  <Accordion className="liquidity-row">
                    <Card>
                      <Card.Header>
                        <div className="liquidity-title">YOUR staked NYANV2-LP </div>
                        <div className="d-flex justify-content-end liquidity-button">
                          <LiquidityToggle eventKey="0"/>
                        </div>
                        
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <Row>
                            <Col lg={10}>Your staked NYANV2-LP</Col>
                            <Col lg={2} className="text-right"><b>{stakedNyanLPAmount.formatted}</b></Col>
                          </Row>   
                          <Row>
                            <Col lg={10}>Available LP tokens</Col>
                            <Col lg={2} className="text-right"><b>{nyanLPBalance.formatted}</b></Col>
                          </Row> 
                          <Row>
                            <Col lg={8}>CATNIPV2 Generation <sup>per day</sup></Col>
                            <Col lg={2}>{(nftDetails && nftDetails.nft.isStaked) && <span className="pinkNFTBonus"><FontAwesomeIcon icon={faRocket} size="1x"/>&nbsp; {nftDetails.nft.bonus} % Bonus generation</span>}</Col>
                            <Col lg={2} className="text-right"><b>{calculatedNIPGeneration}</b></Col>
                          </Row>
                          <Row>
                            <Col lg={10}><b>Claimable CATNIPV2</b></Col>
                            <Col lg={2} className="text-right"><b>{catnipV2Rewards.formatted}</b></Col>
                          </Row>                         
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                  
                  <br/>
                  
                  <Accordion className="liquidity-row">
                    <Card>
                      <Card.Header>
                        <div className="liquidity-title">Your staked CATNIPV2-LP</div>
                        <div className="d-flex justify-content-end liquidity-button">
                          <LiquidityToggle eventKey="1">More info</LiquidityToggle>
                        </div>
                        
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>
                          <Row>
                            <Col lg={10}>Your staked CATNIPV2-LP</Col>
                            <Col lg={2} className="text-right"><b>{stakedNipLPAmount.formatted}</b></Col>
                          </Row>
                          <Row>
                            <Col lg={8}> DNyanV2 Generation <sup>per day</sup></Col>
                            <Col lg={2}>{(nftDetails && nftDetails.nft.isStaked) && <span className="pinkNFTBonus"><FontAwesomeIcon icon={faRocket} size="1x"/>&nbsp; {nftDetails.nft.bonus} % Bonus generation</span>}</Col>
                            <Col lg={2} className="text-right"><b>{calculatedNIPUniGeneration}</b></Col>
                          </Row>
                          <Row>
                            <Col lg={10}><b>Claimable DarkNyan</b></Col>
                            <Col lg={2} className="text-right"><b>{dNyanRewards.formatted}</b></Col>
                          </Row>                             
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>

                  <br/>

                  <Accordion className="liquidity-row">
                    <Card>
                      <Card.Header>
                        <div className="liquidity-title">Your DNYANV2-LP</div>
                        <div className="d-flex justify-content-end liquidity-button">
                          <LiquidityToggle eventKey="1">More info</LiquidityToggle>
                        </div>
                        
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>
                          <Row>
                            <Col lg={10}>Your staked DNYANV2-LP</Col>
                            <Col lg={2} className="text-right"><b>{stakedDNyanLPAmount.formatted}</b></Col>
                          </Row>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                  
                </Col>
                <Col lg="auto"></Col>
            </Row>
            <br/><br/>

      </Container>
  )

}

export default Pool;