import React, {useState, useEffect} from "react";
import { Route, Redirect } from 'react-router-dom';
import Home from "./Home";
import Pool from "./Pool";
import Stake from "./Stake";
import Mine from "./Mine";
import Landing from "./Landing";
import GovernanceVoting from "./GovernanceVoting";
import FundManagement from "./FundManagement";
import Swap from "./swap/Swap";
import LGE from "./swap/LGE";
import ActiveProposals from "./proposals/ActiveProposals";
import Account from './account/Account';

import { useGlobal } from 'reactn';

import ProposalBuilderPage from "./proposalbuilder/Page";

const Routes = (props) => {
    
    const [ web3Instance ] = useGlobal('web3Instance');
    const [featureFlag, setFeatureFlag] = useState(false);


    useEffect(() => {
        setFeatureFlag(props.isFeatureFlagEnabled);
        
        console.log(props.isFeatureFlagEnabled);

    }, [props.isFeatureFlagEnabled]);


    return (
            <div className="application-body">
                <Route exact path='/' component={Landing}/>
                <Route exact path="/app">
                    {web3Instance || featureFlag ? <Home/> : <Redirect to="/" />}
                </Route>

                <Route exact path='/app/swap'>
                    {web3Instance || featureFlag ? <Swap/> : <Redirect to="/" />}
                </Route>

                <Route exact path='/app/LGE'>
                    {web3Instance || featureFlag ? <LGE/> : <Redirect to="/" />}
                </Route>
                
                
                <Route exact path='/app/stake'>
                    {web3Instance || featureFlag ? <Stake/> : <Redirect to="/" />}
                </Route>
                
                <Route exact path='/app/pool'>
                    {web3Instance || featureFlag ? <Pool/> : <Redirect to="/" />}
                </Route>

                <Route exact path='/app/mine'>
                    {web3Instance || featureFlag ? <Mine/> : <Redirect to="/" />}
                </Route>

                <Route exact path='/app/account'>
                    {web3Instance || featureFlag ? <Account/> : <Redirect to="/" />}
                </Route>
                
                <Route exact path='/app/governance/voting'>
                    {web3Instance || featureFlag ? <GovernanceVoting/> : <Redirect to="/" />}
                </Route>

                <Route exact path='/app/fund/manage'>
                    {/*web3Instance || featureFlag ? <FundManagement/> : <Redirect to="/" />*/}
                    <FundManagement/>
                </Route>

                <Route exact path='/app/fund/proposals/create'>
                    {/*web3Instance ? <Swap/> : <Redirect to="/" />*/}
                    <ProposalBuilderPage/>
                </Route>

                {/*                
                

                <Route exact path='/app/fund/manage'>
                    {/*web3Instance ? <Pool/> : <Redirect to="/" />}
                    <FundManagement/>
                </Route>

                <Route exact path='/app/fund/proposals/active'>
                    {/*web3Instance ? <Pool/> : <Redirect to="/" />}
                    <ActiveProposals/>
                </Route>
                
                <Route exact path='/app/fund/proposals/create'>
                    {/*web3Instance ? <Swap/> : <Redirect to="/" />}
                    <ProposalBuilderPage/>
                </Route>
                
                
                */}
            </div>
    )
}

export default Routes