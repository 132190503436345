import React, { useState, useEffect } from "react";



const ReadOnlyAmountField = (props) => {
    const [formattedAmount, setFormattedAmount] = useState('')
    const [fullAmount, setFullAmount] = useState('')
    const [showExact, setShowExact] = useState(false);
    
    useEffect(() => {
      setFormattedAmount(props.formattedAmount);
      setFullAmount(props.fullAmount);
    }, [props.formattedAmount, props.fullAmount]);
    
    
    return (
        <span>
          {showExact && fullAmount > 0 ? (
            <span>{fullAmount} {props.tokenText ? props.tokenText : "available"} &nbsp; <a className='pink-link' onClick={() => setShowExact(false)}>Show less</a></span>
          ) : (
            <span>{formattedAmount} {props.tokenText ? props.tokenText : "available"} &nbsp; {fullAmount > 0 && <a className='pink-link' onClick={() => setShowExact(true)}>Show exact</a>}</span>
          )}
          
        </span>
    )
}

export default ReadOnlyAmountField




