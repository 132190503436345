
import {Accounts } from '../index';
import { getGlobal } from 'reactn';

async function isEnabled(){
  const account = await Accounts.getCurrentAccount();
  if(!account){
    return false;
  }

  const whiteListedAddresses = getGlobal().whiteListedAddresses;
  let isEnabled = whiteListedAddresses.filter(
    address => address == account.substring(account.length-4, account.length)
  );
  
  return isEnabled.length > 0;
}

export {
  isEnabled
}