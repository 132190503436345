/** All interations with the contracts */
import { Web3Util } from '../../index';

import ERC20 from "../../../contracts/ERC20.json";
import ERC721 from "../../../contracts/ERC721.json";
import ERC721Data from "../../../contracts/ERC721DataLayout.json";

import NyanV2Token from "../../../contracts/NyanV2.json";
import NyanV2Data from "../../../contracts/NyanV2DataLayout.json";
import CatnipV2Token from "../../../contracts/CatnipV2.json";
import CatnipV2Data from "../../../contracts/CatnipDataLayout.json";
import DarkNyan from "../../../contracts/DarkNyanV2.json";
import DarkNyanV2Data from "../../../contracts/ERC20DataLayout.json"; // point to ERC20 layout


import NyanLP from "../../../contracts/NyanV2.json";
import NipLP from "../../../contracts/CatnipV2.json";
import DNyanLP from "../../../contracts/DarkNyanV2.json";

// formatter
const Web3Utils = require('web3-utils');

/** GENERIC ERC20 FUNCTIONS */
async function getERC20Functions(address){
    const instance = await Web3Util.getInstance();
        window.ERC20CI = new instance.eth.Contract(
            ERC20.abi,
            address
        );
    return window.ERC20CI
}

/** TOKEN CONTRACTS */
async function getNyanContractInstance(){
    if(!window.nyanV2CI) {
        const instance = await Web3Util.getInstance();
        window.nyanV2CI = new instance.eth.Contract(
            NyanV2Token.abi,
            process.env.REACT_APP_NYANV2_TOKEN_CONTRACT_ADDRESS
        );
    }
    return window.nyanV2CI
}


async function getCatnipContractInstance(){
    if(!window.catnipV2CI) {
        const instance = await Web3Util.getInstance();
        window.catnipV2CI = new instance.eth.Contract(
            CatnipV2Token.abi,
            process.env.REACT_APP_CATNIPV2_TOKEN_CONTRACT_ADDRESS
        );
    }
    return window.catnipV2CI
}

async function getDarkNyanContractInstance(){
    if(!window.darkNyanV2CI) {
        const instance = await Web3Util.getInstance();
        window.darkNyanV2CI = new instance.eth.Contract(
            DarkNyan.abi,
            process.env.REACT_APP_DNYANV2_TOKEN_CONTRACT_ADDRESS
        );
    }
    return window.darkNyanV2CI
}

/** DATA CONTRACTS */
async function getNyanV2DataContractInstance(){
    if(!window.nyanV2NDC) {
        const instance = await Web3Util.getInstance();
        window.nyanV2NDC = new instance.eth.Contract(
            NyanV2Data.abi,
            process.env.REACT_APP_NYANV2_TOKEN_CONTRACT_ADDRESS
        );
    }
    return window.nyanV2NDC
}


async function getCatnipV2DataContractInstance(){
    if(!window.catnipV2NDC) {
        const instance = await Web3Util.getInstance();
        window.catnipV2NDC = new instance.eth.Contract(
            CatnipV2Data.abi,
            process.env.REACT_APP_CATNIPV2_TOKEN_CONTRACT_ADDRESS
        );
    }
    return window.catnipV2NDC
}


async function getDarkNyanDataContractInstance(){
    if(!window.darkNyanV2CDI) {
        const instance = await Web3Util.getInstance();
        window.darkNyanV2CDI = new instance.eth.Contract(
            DarkNyanV2Data.abi,
            process.env.REACT_APP_DNYANV2_TOKEN_CONTRACT_ADDRESS
        );
    }
    return window.darkNyanV2CDI
}

/** LP CONTRACTS */
async function getNyanLPContractInstance(){
    if(!window.nyanV2LPCI) {
        const instance = await Web3Util.getInstance();
        window.nyanV2LPCI = new instance.eth.Contract(
            NyanLP.abi,
            process.env.REACT_APP_NYAN_UNI_TOKEN_CONTRACT_ADDRESS
        );
    }
    return window.nyanV2LPCI
}

async function getCatnipLPContractInstance(){
    if(!window.catnipV2LPCI) {
        const instance = await Web3Util.getInstance();
        window.catnipV2LPCI = new instance.eth.Contract(
            NipLP.abi,
            process.env.REACT_APP_CATNIPUNI_TOKEN_CONTRACT_ADDRESS
        );
    }
    return window.catnipV2LPCI
}

async function getDarkNyanLPContractInstance(){
    if(!window.darkNyanV2LPCI) {
        const instance = await Web3Util.getInstance();
        window.darkNyanV2LPCI = new instance.eth.Contract(
            DNyanLP.abi,
            process.env.REACT_APP_DARKNYAN_UNI_TOKEN_CONTRACT_ADDRESS
        );
    }
    return window.darkNyanV2LPCI
}

/***
 * NFT
 */
async function getNFTContractInstance(){
    if(!window.nftV2Instance) {
        const instance = await Web3Util.getInstance();
        window.nftV2Instance = new instance.eth.Contract(
            ERC721.abi,
            process.env.REACT_APP_NFT_TOKEN_CONTRACT_ADDRESS
        );
    }
    return window.nftV2Instance
}

async function getNFTDataContractInstance(){
    if(!window.nftV2DataInstance) {
        const instance = await Web3Util.getInstance();
        window.nftV2DataInstance = new instance.eth.Contract(
            ERC721Data.abi,
            process.env.REACT_APP_NFT_TOKEN_CONTRACT_ADDRESS
        );
    }
    return window.nftV2DataInstance
}


 // Export each function
 export {
    getERC20Functions,
    getNyanContractInstance,
    getNyanV2DataContractInstance,
    getCatnipV2DataContractInstance,
    getCatnipContractInstance,
    getDarkNyanContractInstance,
    getDarkNyanDataContractInstance,
    getNyanLPContractInstance,
    getCatnipLPContractInstance,
    getDarkNyanLPContractInstance,
    getNFTContractInstance,
    getNFTDataContractInstance
 };