import React, { Fragment, useState, useRef, useEffect } from "react";
import { Typeahead, Highlighter } from "react-bootstrap-typeahead";

import {Addresses} from "../../../utils/V2/proposals";

const AddressTypeAhead = (props) => {

    const [addresses, setAddresses] = useState([]);
    const [isValid, setIsValid] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);
    const [selection, setSelection] = useState([]);

    const ref = useRef();

    useEffect(() => {
      // only fires whenever requestConnectionToWallet is updated.
      async function init() {
        setAddresses(await Addresses.getKnownAddresses());
      }
      init()
    }, []);

    useEffect(() => {
      if(selection.length > 0){
        let addressesValid = selection.filter(selectedOption => {
          return Addresses.isValidAddress(selectedOption.address) === false;
        })
        // set feedback
        if(addressesValid.length > 0){
          setIsValid(false);
          setIsInvalid(true);
        } else {
          setIsValid(true);
          setIsInvalid(false);
        }
      } else {
        //reset
        setIsValid(false);
        setIsInvalid(false);
      }

      
    }, [selection]);

    const filterByAddress = (option, props) => (
      option.name.toLowerCase().indexOf(props.text.toLowerCase()) !== -1 ||
      option.address.toLowerCase().indexOf(props.text.toLowerCase()) !== -1
    );

    return (
        <Typeahead
                allowNew
                clearButton
                filterBy={filterByAddress}
                newSelectionPrefix="Add address: "
                options={addresses}
                placeholder="Type address..."
                labelKey="address"
                isInvalid={isInvalid}
                isValid={isValid}
                id={props.id}
                inputProps={{ autoComplete: "off" }, {autoComplete: "chrome-off"}}
                onChange={(s) => setSelection(s)}
                renderMenuItemChildren={(option, props, idx) => (
                  <Fragment>
                    <Highlighter search={props.text ? props.text : ""}>
                      {option.name}
                    </Highlighter>
                    <div>
                      <small>
                        {option.address}
                      </small>
                    </div>
                  </Fragment> 
                  
                )}

                ref={ref}
              />      
    )
}

export default AddressTypeAhead