import React, { useState, useEffect } from "react";

import nyanGif from '../assets/nyan-logo.png';

import { Button, Row, Col, Container, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import StakeOrUnstakeModal from './modals/StakeOrUnstakeModal';
import ReadOnlyAmountField from './fields/ReadOnlyAmountField';
import ReadOnlyHeaderAmountField from './fields/ReadOnlyHeaderAmountField';

import nyanLogo from '../assets/logos/cat-logo.png';
import catnipLogo from '../assets/catnip.png';

import { Web3Util } from '../utils';

import { Claim, Balances as V2Balances, Allowance, Approve, Stake as StakingUtil } from '../utils/V2';

import { useGlobal } from 'reactn';

import '../style/Stake.css';

const Stake = (props) => {
    
    const [nyanLPBalance, setNyanLPBalance]  = useState({}); // nyan v2 balance
    
    const [stakeAllowed, setStakeAllowed] = useState(false); // is allowed to stake?
    const [ethBalance, setEthBalance] = useState({});
    const [stakedNyanLPAmount, setStakedNyanLPAmount]  = useState({});
    //const [unstakeAmount, setUnstakeAmount]  = useState(0);
    const [catnipRewards, setCatnipRewards]  = useState({});

    
    const [ web3Instance ] = useGlobal('web3Instance');

    /*
    const [ nyanApproved ] = useGlobal('nyanApproved');
    const [ nyanApprovalInProgress ] = useGlobal('nyanApprovalInProgress');
    */

    // modal stuff
    const [isStakeModalShown, setIsStakeModalShown] = useState(false);
    const showStakeModal = () => setIsStakeModalShown(true);
    const hideStakeModal = () => setIsStakeModalShown(false);

    const [isUnstakeModalShown, setIsUnstakeModalShown] = useState(false);
    const showUnstakeModal = () => setIsUnstakeModalShown(true);
    const hideUnstakeModal = () => setIsUnstakeModalShown(false);

    
    const approveNyan = () => Approve.approveNyanLPStaking();
    
    // init staking page
    useEffect(() => {
        // only fires whenever requestConnectionToWallet is updated.
        async function init() {
            
            // NYAN LP tokens
            setNyanLPBalance(await V2Balances.getMyNyanLPBalance());
            // can I stake?
            setStakeAllowed(await Allowance.getMyNyanStakeAllowed());
            
            console.log("****")
            console.log(await Allowance.getMyNyanStakeAllowed());

            // NYAN LP Tokens that are staked
            setStakedNyanLPAmount(await V2Balances.getMyNyanStakedLPBalance()); //currently lp'ed nyan
            
            // Set Catnip Rewards
            setCatnipRewards(await V2Balances.getMyCatnipRewards());
             
            /* HACK */
            if(web3Instance) return false;
            let instance = await Web3Util.createInstance();
            if(!instance) console.log('cannot open modal')
        }
        init()
    }, []);

    /**
     * Claim the rewards
     * @param {} rewards 
     */
    const claimRewards = async () => {
        if(catnipRewards.full > 0){
          await Claim.harvestMyCatnipRewards()
          setCatnipRewards(await V2Balances.getMyCatnipRewards());
        }
    }

    const stakeNyanLP = async (amountToStake) => {
        let result = StakingUtil.stakeNyanV2LP(amountToStake);
    }

    const unstakeNyanLP = async (amountToUnstake) => {
        let result = StakingUtil.unstakeNyanV2LP(amountToUnstake);
    }

    /**
     * Props for the stake and unstake modal.
     */
    const stakeNyanModalProps = {
        isModalShown: isStakeModalShown,
        hideModal: hideStakeModal,
        availableAmount: nyanLPBalance,
        stakeOrUnstakeMethod: stakeNyanLP,
        title: 'Stake NYANV2-LP',
        availableText: 'NYANV2-LP available',
        description: 'Stake your NYANV2-LP and earn CATNIPV2!'
    }

    const unstakeNyanModalProps = {
        isModalShown: isUnstakeModalShown,
        hideModal: hideUnstakeModal,
        availableAmount: stakedNyanLPAmount,
        stakeOrUnstakeMethod: unstakeNyanLP,
        title: 'Unstake NYANV2-LP',
        availableText: 'NYANV2-LP staked',
        description: 'UnStake your NYANV2-LP by filling in the amount.'
    }

    return (
        <Container fluid>
        
            <Row className="justify-content-center align-items-center">
    
                <Col lg="auto">
                    <div styles={{backgroundImage: `url(${nyanGif})`}}></div>
                    <h1 className="title">STAKE NYANV2 Liquidity</h1>
                    <p className="text-center">
                        Deposit NYAN-V2/ETH liquidity tokens and earn <b>CATNIPV2</b><br/>
                        Get the LP tokens <a href={`https://app.uniswap.org/#/add/${process.env.REACT_APP_NYANV2_TOKEN_CONTRACT_ADDRESS}/ETH`} target="_new">here</a><br/>
                        New to Yield farming? Read our extensive guide <a href="https://www.learnnyan.finance/" target="_new">here</a>
                    </p>
                </Col>
            </Row>
            
            <br/>
    
            <Row className="justify-content-center align-items-center">
                <Col lg="auto"></Col>

                <Col lg={3}>
                <Card className="actionbox rewardbox text-center">
                    <div className="text-center">
                        <Card.Img className="actionbox-logo-image top" variant="top" src={catnipLogo}/>
                    </div>
                    <Card.Body className="actionbox-body text-center">
                        <Card.Title>
                            <ReadOnlyHeaderAmountField formattedAmount={catnipRewards.formatted} fullAmount={catnipRewards.full}/>
                        </Card.Title>
                        <Card.Text className="card-text">
                            CATNIPV2 Earned
                            <br/><br/>
                        </Card.Text>
                        <Button className="pink-button actionbox-button" variant="primary" disabled={catnipRewards < 0.1} onClick={claimRewards.bind(catnipRewards)}>Harvest</Button>
                    </Card.Body>
                </Card>
                    
                </Col>
                <Col lg={3}>
                    <Card className="actionbox rewardbox text-center">
                        <div className="text-center">
                            <Card.Img className="actionbox-logo-image top" variant="top" src={nyanLogo}/>
                        </div>
                        <Card.Body className="actionbox-body text-center">
                            <Card.Title>
                                <ReadOnlyHeaderAmountField formattedAmount={stakedNyanLPAmount.formatted} fullAmount={stakedNyanLPAmount.full}/>
                            </Card.Title>
                            <Card.Text className="card-text">
                                NYANV2-LP staked <br/>
                                <ReadOnlyAmountField formattedAmount={nyanLPBalance.formatted} fullAmount={nyanLPBalance.full}/>
                            </Card.Text>
                            {stakeAllowed ? 
                            <div>
                                <Button className="pink-button actionbox-button" variant="primary" onClick={showUnstakeModal} disabled={stakedNyanLPAmount.full == 0}>Unstake</Button>
                                &nbsp;
                                <Button className="pink-button actionbox-button" variant="primary" onClick={showStakeModal} disabled={nyanLPBalance.full == 0}>Stake</Button> 
                            </div>
                                :
                                <Button className="pink-button actionbox-button" variant="primary" onClick={approveNyan} disabled={nyanLPBalance.full < 0}>Approve first</Button> 
                            }    
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg="auto"></Col>
            </Row>
            
            <br/>

            <Row>
                <Col className="justify-content-center align-items-center text-center"><Link to="/app"><Button variant="link" className="as-link" >Back to overview</Button></Link></Col>
            </Row>

          
            <StakeOrUnstakeModal {...unstakeNyanModalProps}/>
            <StakeOrUnstakeModal {...stakeNyanModalProps}/>

        </Container>
    )
}

export default Stake;