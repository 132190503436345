import { Contracts as V2Contracts, GovernanceContracts} from './index';
import {Accounts, Formatter} from '../index';

const Web3Utils = require('web3-utils');


const getGovernanceProposal = async() => {
    
    let instance = await GovernanceContracts.getGovernanceVotingContractInstance();


    let proposalId = await instance.methods.proposals(0).call();
    let proposal = await instance.methods.getProposal(proposalId).call();
    
    let optionVotes = proposal[4];

    let formattedOptionVotes = optionVotes.map(vote => {
        return Formatter.fromWei(vote);
    });

    // get current block time
    let governanceProposal = {
        proposalId: proposalId,
        question: proposal[0],
        options: proposal[2],
        optionImgs: proposal[3],
        optionVotes: formattedOptionVotes,
        voteEndBlock: proposal[5]
    }
    return governanceProposal;
}

const getVoteCount = async() => {
  let instance = await GovernanceContracts.getGovernanceVotingContractInstance();
  let account = await Accounts.getCurrentAccount();

  let voteCount = await instance.methods.getVoteCount(account).call();
  console.log(voteCount)
  return Formatter.getRoundedAndFullBalance(voteCount);
}

const getMyCurrentProposalVotes = async() => {
    /** PLACEHOLDER FOR GETTING THE CURRENT AMOUNT OF VOTES PER OPTION */
    return 0;
}

/*
const isGovernanceVotingApproved = async() => {
    let instance = await GovernanceContracts.getGovernanceVotingContract();
    let account = await Accounts.getCurrentAccount();

    let votingAllowance = await instance.methods.allowance(account, process.env.REACT_APP_GOVERNANCE_CONTRACT_ADDRESS).call();
    return votingAllowance > 0;
}

const approveGovernanceVoting = async() => {
    let instance = await GovernanceContracts.getNyanContractInstance();
    let account = await Accounts.getCurrentAccount();
    let amount = 33000;

    let approveStaking = await instance.methods.approve(process.env.REACT_APP_GOVERNANCE_CONTRACT_ADDRESS, Web3Utils.toWei(amount.toString())).send({
        from: account
    });
    return;
}
*/

const voteForGovernanceProposal = async(index, nrOfVotes, proposal) => {
    let instance = await GovernanceContracts.getGovernanceVotingContractInstance();
    let account = await Accounts.getCurrentAccount();
    let votes = Web3Utils.toWei(nrOfVotes);
    console.log(index, proposal.proposalId, votes)
    let result = await instance.methods.voteForProposal(votes, parseInt(index), proposal.proposalId).send({
        from: account
    });
}



export {
    getGovernanceProposal,
    getMyCurrentProposalVotes,
    //isGovernanceVotingApproved,
    //approveGovernanceVoting,
    voteForGovernanceProposal,
    getVoteCount
}