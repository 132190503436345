import React from "react";
import { Row, Col, Container} from 'react-bootstrap';

import { Link } from 'react-router-dom';

import nyanGif from '../assets/nyan-logo.png';

import stakeLogo from '../assets/Nyan_stake.gif';
import mineLogo from '../assets/Dark_Nyan.gif';
import pumpLogo from '../assets/Nyan_pump.gif';


const FundManagement = (props) => {


    return (
        <Container fluid>
            <Row className="justify-content-center align-items-center">
                <Col lg="auto">
                    <div styles={{backgroundImage: `url(${nyanGif})`}}></div>
                    <h1 className="title">Fund Voting & Bids</h1>
                    <p className="text-center">
                        Read all about current proposals and vote on your favorite one!.<br/>
                        Stake your NYANV2 LP to vote.<br/>
                        More information can be found by clicking this <a href="#">link.</a>
                    </p>
                </Col>
            </Row>

            <br/><br/>

            <Row className="justify-content-center align-items-center">
                <Col lg="auto"></Col>

                <Col lg={3}>
                    <Link to="/app/fund/proposals/active" className="router-link">
                        <div className="card actionbox">
                            <div className="card-horizontal">
                                <div className="img-square-wrapper">
                                    <img className="actionbox-logo-image" alt="nyan logo" src={stakeLogo}/>
                                </div>
                                <div className="card-body actionbox-body">
                                    <h4 className="card-title">Vote</h4>
                                    <p className="card-text">See current proposals and vote for your favorite</p>
                                    <br/>
                                </div>
                            </div>
                        </div>
                    </Link>
                </Col>

                <Col lg={3}>
                    <Link to="/app/fund/proposals/create" className="router-link">
                        <div className="card actionbox">
                            <div className="card-horizontal">
                                <div className="img-square-wrapper">
                                    <img className="actionbox-logo-image" alt="nyan logo" src={mineLogo}/>
                                </div>
                                <div className="card-body actionbox-body">
                                    <h4 className="card-title">Create</h4>
                                    <p className="card-text">Create a proposal using the Proposal builder.</p>
                                    <br/>
                                </div>
                            </div>
                        </div>
                    </Link>
                </Col>
                <Col lg={3}>
                    <Link to="/app/fund/distribution" className="router-link">
                        <div className="card actionbox">
                            <div className="card-horizontal">
                                <div className="img-square-wrapper">
                                    <img className="actionbox-logo-image" alt="nyan logo" src={pumpLogo}/>
                                </div>
                                <div className="card-body actionbox-body">
                                    <h4 className="card-title">Claim</h4>
                                    <p className="card-text">Hedgefund distributions you are eligible to claim</p>
                                    <br/>
                                </div>
                            </div>
                        </div>
                    </Link>
                </Col>
                <Col lg="auto"></Col>
            </Row>

        </Container>
    )
}

export default FundManagement;