import React, { useState, useEffect, useContext  } from "react";
import {Button, Row, Col, Container, Card} from 'react-bootstrap';
import nyanGif from '../../assets/nyan-logo.png';
import nyanLogo from '../../assets/nyan-logo.png';
import catnipLogo from '../../assets/catnip.png';
import dNyanLogo from '../../assets/dNyan.png';


import { Balances, Web3Util, GovernanceVoting as GovernanceVotingUtil, Formatter } from "../../utils";
import { Balances as V2Balances, Swap as SwapUtil, Approve, Allowance} from "../../utils/V2";

import { useGlobal } from 'reactn';

import SwapCard from './components/SwapCard';

import '../../style/Swap.css';
import { approveNipSwap } from "../../utils/V2/contracts/Approve";

const Swap = (props) => {

    const [nyanInputAmount, setNyanInputAmount] = useState(0);
    const [nipInputAmount, setNipInputAmount] = useState(0);
    const [dNyanInputAmount, setDNyanInputAmount] = useState(0);
    
    const [nyanV1Balance, setNyanV1Balance] = useState(0);
    const [nyanV2Balance, setNyanV2Balance] = useState(0);
    
    const [catnipV1Balance, setCatnipV1Balance] = useState(0);
    const [catnipV2Balance, setCatnipV2Balance] = useState(0);
    
    const [dNyanV1Balance, setDNyanV1Balance] = useState(0);
    const [dNyanV2Balance, setDNyanV2Balance] = useState(0);

    const [isNyanSwapApproved, setIsNyanSwapApproved] = useState(false);
    const [isNipSwapApproved, setIsNipSwapApproved] = useState(false);
    const [isDNyanSwapApproved, setIsDNyanSwapApproved] = useState(false);

    const [web3Instance] = useGlobal('web3Instance');
    
    const swapAmountClassNames = {
        rowClassName: 'row-swap-amount justify-content-center align-items-center',
        colClassName: 'col-swap-amount',
        fieldClassName: 'field-swap-amount',
        maxButtonClassName: 'field-swap-max-button'
    }

    // init staking page
    useEffect(() => {
        // only fires whenever requestConnectionToWallet is updated.
        async function init() {

            setNyanV1Balance(await Balances.getMyNyanBalance());
            setNyanV2Balance(await V2Balances.getMyNyanBalance());

            setCatnipV1Balance(await Balances.getMyCatnipBalance())
            //setCatnipV1Balance(100);
            setCatnipV2Balance(await V2Balances.getMyCatnipBalance())
        
            setDNyanV1Balance(await Balances.getMyDarkNyanBalance())
            setDNyanV2Balance(await V2Balances.getMyDarkNyanBalance())

            setIsNyanSwapApproved(await Allowance.getMyNyanSwapAllowed());
            setIsNipSwapApproved(await Allowance.getMyCatnipSwapAllowed());
            setIsDNyanSwapApproved(await Allowance.getMyDarkNyanSwapAllowed());
            
            /* HACK */
            if(web3Instance) return false;
            let instance = await Web3Util.createInstance();
            if(!instance) console.log('cannot open modal')
        }
        init()
    }, []);

    const setNyanSwapAmount = (amount) => setNyanInputAmount(amount);
    const setNipSwapAmount = (amount) => setNipInputAmount(amount);
    const setDNyanSwapAmount = (amount) => setDNyanInputAmount(amount);

    const approveNyanSwap = () => Approve.approveNyanSwap();
    const approveNipSwap = () =>  Approve.approveNipSwap();
    const approveDNyanSwap = () => Approve.approveDNyanSwap();

    const swapNyan = () => {
        SwapUtil.swapNyanV1ToV2(nyanInputAmount)
        //swapNyanV1 -> V2 amount
    }

    const swapCatnip = () => {
        SwapUtil.swapNipV1ToV2(nipInputAmount)
    }

    const swapDNyan = () => {
        SwapUtil.swapDNyanV1(dNyanInputAmount)
    }

    const nyanSwapProps = {
        v1Text: 'Nyan V1',
        v2Text: 'Nyan V2',
        v1Balance: nyanV1Balance,
        v2Balance: nyanV2Balance,
        logo: nyanLogo,
        approved: isNyanSwapApproved,
        approveFunction: approveNyanSwap,
        setAmountFN: setNyanSwapAmount,
        swapFunction: swapNyan 
    }

    const nipSwapProps = {
        v1Text: 'NIP V1',
        v2Text: 'NIP V2',
        v1Balance: catnipV1Balance,
        v2Balance: catnipV2Balance,
        logo: catnipLogo,
        approved: isNipSwapApproved,
        approveFunction: approveNipSwap,
        setAmountFN: setNipSwapAmount,
        swapFunction: swapCatnip
    }

    const dNyanSwapProps = {
        v1Text: 'dNYAN V1',
        v2Text: 'dNYAN V2',
        v1Balance: dNyanV1Balance,
        v2Balance: dNyanV2Balance,
        logo: dNyanLogo,
        approved: isDNyanSwapApproved,
        approveFunction: approveDNyanSwap,
        setAmountFN: setDNyanSwapAmount,
        swapFunction: swapDNyan
    }

    


    return (
        <Container fluid>
            <Row className="justify-content-center align-items-center">
                <Col lg="auto">
                    <div styles={{backgroundImage: `url(${nyanGif})`}}></div>
                    <h1 className="title">Swap your tokens</h1>
                    <p className="text-center">
                        In order to fully unlock the new ecosystem, swap your tokens now!<br/>
                    </p>
                </Col>
            </Row>

            <br/><br/>

            <Row>
                <Col>
                    <Card className="actionbox swapbox text-center">
                    <Row className="justify-content-center align-items-center">
                        <Col lg={4} className="swapcard-col">
                            <SwapCard {...nyanSwapProps}/>
                        </Col>
                        <Col lg={4} className="swapcard-col">
                            <SwapCard {...nipSwapProps}/>
                        </Col>
                        <Col lg={4} className="swapcard-col">
                            <SwapCard {...dNyanSwapProps}/>
                        </Col>
                    </Row>     
                    </Card>
                </Col>
            </Row>
            
            <br></br>
        </Container>
    )

}

export default Swap;