import { Contracts } from './index';
import { Accounts } from '../index';

const Web3Utils = require('web3-utils');

async function swapNyanV1ToV2(amount){
  
  let account = await Accounts.getCurrentAccount();
  let instance = await Contracts.getNyanContractInstance();

  let result = await instance.methods.swapNyanV1(Web3Utils.toWei(amount.toString())).send({
      from: account
  });
  return result;
}

async function swapNipV1ToV2(amount){
  
  let account = await Accounts.getCurrentAccount();
  let instance = await Contracts.getCatnipContractInstance();
  
  let result = await instance.methods.swapCatnipV1(Web3Utils.toWei(amount.toString())).send({
      from: account
  });
  return result; 
}

async function swapDNyanV1(amount){

  let account = await Accounts.getCurrentAccount();
  let instance = await Contracts.getDarkNyanContractInstance();

  let result = await instance.methods.swapDNyanV1(Web3Utils.toWei(amount.toString())).send({
      from: account
  });
  return result;
  
}
export {
  swapNyanV1ToV2,
  swapNipV1ToV2,
  swapDNyanV1
}