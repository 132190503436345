import { Contracts, Balances as V2Balances, NFT } from './index';
import { Accounts, Web3Util, Formatter} from '../index';

// formatter
const Web3Utils = require('web3-utils');


/** Function to determine when the next reward calculation happens */
async function getNextRewardDateAndTime(){
  
  let instance = await Contracts.getNyanV2DataContractInstance();
  let web3Instance = await Web3Util.createInstance();

  let lastNyanCheckpoint = await instance.methods.lastNyanCheckpoint().call();
  let currentBlockNumber = await web3Instance.eth.getBlockNumber();
  let blocksLeft = (currentBlockNumber - lastNyanCheckpoint);
  let timeLeft = (blocksLeft * 15 / 3600).toFixed(2);
  console.log(lastNyanCheckpoint);
  console.log(currentBlockNumber);
  console.log(blocksLeft);
  console.log(timeLeft);

   
  /*
  
  //let blocksLeft = 12000; // hack
  //if(blocksLeft > 0){
      let poll = [];
      let highestVote = 0;
      let highestVoteValue = "";
      currentProposal.isActive = blocksLeft > 0;
      currentProposal.endHours = (blocksLeft * 15 / 3600).toFixed(2);
      */

}


const getLastBlockCatnipStaked = async() => {
  let currentAccount = await Accounts.getCurrentAccount();
  let instance = await Contracts.getCatnipV2DataContractInstance();
  let staked = await instance.methods.stakedBalances(currentAccount).call();
  return staked.lastBlockChecked;
}

const getLastBlockNyanStaked  = async() => {
  let currentAccount = await Accounts.getCurrentAccount();
  let instance = await Contracts.getNyanV2DataContractInstance();
  let staked = await instance.methods.userStake(currentAccount).call();
  return staked.lastBlockChecked;
}

const getMyCatnipGeneration = async() => {
  let stakedNyanLPAmount = await V2Balances.getMyNyanStakedLPBalance();
  let stakedDnyanLPAmount = await V2Balances.getMyDarkNyanStakedLPBalance();
  let generation = ((+stakedNyanLPAmount.full + +stakedDnyanLPAmount.full) * 6500) / 100000;

  // get the NFT bonus if applicable
  let nft = await NFT.getMyNFTDetails();
  if(nft && nft.nft.isStaked) {
    generation = generation / 100 * (100+nft.tier.bonus);
  }
  console.log(generation)
  console.log(Formatter.formatDecimals(generation, 6));
  return Formatter.formatDecimals(generation, 6);
}

const getMyDarkNyanGeneration = async() => {
  let stakedCatnipLPAmount = await V2Balances.getMyCatnipStakedLPBalance();
  let generation = stakedCatnipLPAmount.full * 6500 / 3600000;
  // get the NFT bonus if applicable
  let nft = await NFT.getMyNFTDetails();
  if(nft && nft.nft.isStaked) {
    generation = generation / 100 * (100+nft.tier.bonus);
  }
  return Formatter.formatDecimals(generation, 6);
}


export {
  getNextRewardDateAndTime,
  getLastBlockCatnipStaked,
  getLastBlockNyanStaked,
  getMyCatnipGeneration,
  getMyDarkNyanGeneration
}