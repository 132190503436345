import React, { useState, useEffect  } from "react";
import { Row, Col, Container, Card, Button, Image} from "react-bootstrap";

import Accordion from "react-bootstrap/Accordion"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";

import { GovernanceVoting as GovernanceVotingUtil, Balances as V2Balances } from "../utils/V2";
import StakeOrUnstakeModal from "./modals/StakeOrUnstakeModal";
import { Web3Util } from "../utils";
import { useGlobal } from "reactn";

import nyanGif from "../assets/nyan-logo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faBoxBallot } from '@fortawesome/pro-solid-svg-icons';

import '../style/GovernanceVoting.css';

const GovernanceVoting = (props) => {

    const [currentProposal, setCurrentProposal] = useState({});
    const [votesLeft, setVotesLeft] = useState({full: '', formatted: ''});
    const [totalVotes, setTotalVotes] = useState({});

    // voting const
    const [ voteIndex, setVoteIndex] = useState(null);
    const [ voteText, setVoteText] = useState('');
    const [ web3Instance ] = useGlobal('web3Instance');
    const [ voteButtonHidden, setVoteButtonHidden] = useState([]);
    const [ hasVoted, setHasVoted] = useState(false);

    // modal
    const [isVoteAmountModalShown, setIsVoteAmountModalShown] = useState(false);
    const showVoteAmountModal = () => setIsVoteAmountModalShown(true);
    const hideVoteAmountModal = () => setIsVoteAmountModalShown(false);

    // init staking page
    useEffect(() => {
        // only fires whenever requestConnectionToWallet is updated.
        async function init() {

            /*
            setIsGovernanceVotingApproved(await GovernanceVotingUtil.isGovernanceVotingApproved());
            */


            let proposal = await GovernanceVotingUtil.getGovernanceProposal();
            enrichProposal(proposal);
            console.log(currentProposal);

            //let votesLeft = await GovernanceVotingUtil.getVoteCount();
            setVotesLeft(await GovernanceVotingUtil.getVoteCount());
            setTotalVotes(await V2Balances.getMyTotalVotingTokens());

            /* HACK */
            if(web3Instance) return false;
            let instance = await Web3Util.createInstance();
            if(!instance) console.log('cannot open modal')            
        }
        init()
    }, []);

    useEffect(() => {
        if(votesLeft.full < totalVotes.full) {
            // person has voted
            setHasVoted(true);
        }
    }, [votesLeft, totalVotes]);

    


    const enrichProposal = async(currentProposal) => {
        let instance = await Web3Util.createInstance();
        let currentBlockNumber = await instance.eth.getBlockNumber();
        let blocksLeft = (currentProposal.voteEndBlock - currentBlockNumber);

        currentProposal.isActive = blocksLeft > 0;
        currentProposal.endHours = (blocksLeft * 15 / 3600).toFixed(2);

        currentProposal.enrichedOptions = [];

        currentProposal.options.map((option, index) => {
            let tmp = {};
            tmp.text = option;
            tmp.image = currentProposal.optionImgs[index];
            tmp.votes = currentProposal.optionVotes[index];
            currentProposal.enrichedOptions.push(tmp);
        });
        setCurrentProposal(currentProposal);
    }

    // we set the current index & chosen option upon clicking on it and opening the modal.
    const showVotingModal = (option, index) => {
        setVoteIndex(index)
        setVoteText(option.text);
        setIsVoteAmountModalShown(true);
    }

    const executeVote = async(nrOfVotes) => {
        GovernanceVotingUtil.voteForGovernanceProposal(voteIndex, nrOfVotes, currentProposal);
    }

    /**
     * Component
     * @param {} param0 
     */
    const VoteToggle = ({ children, eventKey }) => {
        const decoratedOnClick = useAccordionToggle(eventKey, () => {
          voteButtonHidden[eventKey] = !voteButtonHidden[eventKey];
          setVoteButtonHidden(voteButtonHidden);
        })
      
        return (
          <button
            type="button"
            variant="link"
            className="liquidity-more-info-button"
            onClick={decoratedOnClick}
          >
            {!voteButtonHidden[eventKey] ? "More info" : "Close"}
          </button>
        );
    }

    const totalVotesFormatter = (votes) => {
        return "";
    }


    // Constants for modal
    const votingAmountModalProps = {
        isModalShown: isVoteAmountModalShown,
        hideModal: hideVoteAmountModal,
        availableAmount: votesLeft,
        stakeOrUnstakeMethod: executeVote,
        title: 'Vote for proposal',
        availableText: 'Available LP',
        description: `You are voting for option: "${voteText}"`
    }

    return (
        <Container fluid>
            <Row className="justify-content-center align-items-center">
                <Col lg="auto">
                    <div styles={{backgroundImage: `url(${nyanGif})`}}></div>
                    <h1 className="title">Governance voting</h1>
                    <p className="text-center">Community driven governance voting.</p>
                    <p className="text-center">
                        Use your staked NYAN-2 LP to let your voice be heard by voting for a proposal you support.<br/>
                        Each staked NYAN-2 LP equals 1 vote.<br/>
                        Proposals can be brought up for voting by any member.
                    </p>
                </Col>
            </Row>

            <br/><br/>
            
                <Row className="justify-content-center align-items-center">
                    <Col lg="auto"><h4 className="balances-title">Governance proposals</h4></Col>
                </Row>
           
                <Row className="justify-content-center align-items-center">
                    <Col lg={1}></Col>
                    <Col lg={10}>
                    {currentProposal.isActive ? (
                        <Accordion className="liquidity-row">
                            <Card>
                            <Card.Header>
                                {currentProposal.question ? 
                                <Row>
                                    <Col lg={4} className="liquidity-title">
                                        {currentProposal.question}
                                    </Col>
                                    <Col lg="auto"></Col>
                                    <Col lg={2}>
                                        <FontAwesomeIcon icon={faBoxBallot} size="1x" className="ballot-icon"/>&nbsp; {votesLeft.formatted} / {totalVotes.formatted} votes
                                    </Col>
                                    <Col lg={2}>
                                        <FontAwesomeIcon icon={faClock} size="1x" className="rocket-icon"/>&nbsp; {currentProposal.endHours} hours
                                    </Col>
                                    <Col lg={2} className="liquidity-button text-right"><VoteToggle eventKey="0"/></Col>
                                </Row>   
                                : 
                                <Row>
                                    <Col className="liquidity-no-proposal">No active proposal(s) yet....</Col>
                                </Row>
                                } 
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <Row className="text-center">
                                        <Col>{currentProposal.question}</Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                    {currentProposal.enrichedOptions.map((option, index) => {
                                        return (
                                            <Col lg={4} key={index}>
                                                <Card className="actionbox governancevotingbox text-center">
                                                    <Card.Body className="actionbox-body text-center">
                                                        <Card.Title>
                                                        Option {index+1} <br/>
                                                        {hasVoted && <span className="vote-text">({option.votes} votes)</span>}<br/>
                                                        {option.image != "" && <Image className="explanationbox-logo-image" src={nyanGif} fluid/>}
                                                        </Card.Title>
                                                        <Card.Body className="card-text">
                                                            <div>{option.text}</div><br/>
                                                        </Card.Body>
                                                        
                                                        <Button className="pink-button actionbox-button" variant="primary" disabled={false} onClick={() => showVotingModal(option, index)}>Vote</Button>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        )
                                    })}
                                    </Row>
                                </Card.Body>
                            </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        ) : ( 
                            <Card>
                                <Card.Header>
                                    <Row>
                                        <Col lg={10} className="liquidity-title">
                                            {currentProposal.question}
                                        </Col>
                                        <Col lg={2} className="liquidity-button text-right">Closed</Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body>
                                <Row>
                                    <Col lg={1}></Col>
                                    <Col lg={10}>
                                        
                                    </Col>
                                    <Col lg={1}></Col>
                                </Row>
                                </Card.Body>
                            </Card>
                        )}
                    </Col>
                    <Col lg={1}></Col>
                </Row>
           
            <StakeOrUnstakeModal {...votingAmountModalProps} />
        </Container>
        
    )
}

export default GovernanceVoting