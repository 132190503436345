const Web3Utils = require('web3-utils');

function isValidAddress(address) {
  return Web3Utils.isAddress(address);
}

function getKnownAddresses(){
  
  let data = require(`../../../assets/data/proposalbuilder/knownaddresses.json`);
  return data;
}

export {
  isValidAddress,
  getKnownAddresses
}