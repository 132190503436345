
import { Accounts } from '../index';
import { getGlobal } from 'reactn';

function getInterfaces(){
  
  let abis = [];
  let contractsToLoad = [{
    name: 'ERC20 Default Interface',
    file: 'ERC20.json'
  }];

  contractsToLoad.forEach(contract => {
    let data = require(`../../../contracts/${contract.file}`);
    // get only functions
    let functions = data.abi.filter((abiEntry) => abiEntry.type == 'function')

    // sort alphabetically
    functions.sort(function(a, b) {
      return a.name.localeCompare(b.name);
    });

    // push to array
    abis.push({
      interface: contract,
      abi: functions
    });
    
  })
  return abis;
}

export {
  getInterfaces
}