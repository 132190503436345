import React, {useEffect, useState} from "react";
import { Link } from 'react-router-dom';
import { Row, Col, Image, Nav, Navbar, Button } from 'react-bootstrap';
import Catlogo from '../assets/logos/cat-logo.png';

import { Web3Util } from '../utils';

import { useGlobal } from 'reactn';

import '../style/Header.css';


const Header = (props) => {

    const [ web3Instance ] = useGlobal('web3Instance');
    const [ requestConnectionToWallet ] = useGlobal('requestConnectionToWallet');
    const [ menuButtonsEnabled, setMenuButtonsEnabled ] = useState(false);
    const [ isLGEOver, setIsLGEOver] = useState(false);

    useEffect(() => {

            // only fires whenever requestConnectionToWallet is updated.
            async function setMenuButtons() {
                //setIsLGEOver(await Swap.getLGEBool());

                //console.log('setmenubuttons')
                if(web3Instance) setMenuButtonsEnabled(true);
            }

        setMenuButtons()
    }, [web3Instance])

    // This will only re-run if the value of `props.filters` changes
    useEffect(() => {
        async function emulateWeb3() {
            /* HACK */
            //if(web3Instance) return false;
            //let instance = await Web3Util.createInstance();
            //if(!instance) console.log('cannot open modal')
        }
        emulateWeb3()
    }, [props.featureFlagEnabled]);
    
    return (
        <Row>
            
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top" className="header">
                <Navbar.Brand href="/">
                    <div className="logo-container">
                        <Image src={Catlogo} className="logo-image"></Image>
                        <div className="logo-text">NYAN.FINANCE</div>
                    </div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                    </Nav>
                    <Nav className="justify-content-center align-items-center">
                        <Link to="/" className="link menu">Home</Link>

                        {menuButtonsEnabled && <Link to="/app" className="link menu">Fund management</Link> }
                        {menuButtonsEnabled && <Link to="/app/lge" className="link menu">LGE</Link> }
                        {menuButtonsEnabled && <Link to="/app/swap" className="link menu">Swap</Link> }
                        {menuButtonsEnabled && <Link to="/app/pool" className="link menu">Pool info</Link> }
                        {menuButtonsEnabled && <Link to="/app/governance/voting" className="link menu">Governance voting</Link> }
                        {/*web3Instance && <Link to="/app/governance/voting" className="link menu">Governance voting</Link> */}
                        {/*<Link to="/learnmore" className="link menu">Learn more</Link>*/}
                        {menuButtonsEnabled ? <Link to="/app/account"><Button className="pink-menu-button">Account</Button></Link> : null }
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </Row>        
    )
}

export default Header;
