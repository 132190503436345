import { Contracts, Allowance } from '../index';
import { Contracts as V1Contracts } from '../../index';
import { Accounts } from '../../index';

const Web3Utils = require('web3-utils');


/**
 * Approve LP Staking
 */
async function approveNyanLPStaking(){
  let account = await Accounts.getCurrentAccount();
  let instance = await Contracts.getERC20Functions(process.env.REACT_APP_NYAN_UNI_TOKEN_CONTRACT_ADDRESS); // get V2 
  let totalNyanSupply = await instance.methods.totalSupply().call();

  let approveStaking = await instance.methods.approve(process.env.REACT_APP_NYANV2_TOKEN_CONTRACT_ADDRESS, Web3Utils.toWei(totalNyanSupply.toString())).send({
      from: account
  });

  let success = (approveStaking["status"]) ? true : false;
  return success;
}

async function approveCatnipLPStaking(){
  let account = await Accounts.getCurrentAccount();
  let instance = await Contracts.getERC20Functions(process.env.REACT_APP_CATNIPUNI_TOKEN_CONTRACT_ADDRESS); // get V2 DNYAN and approve CATNIP V2 contract on that.
  let totalNipSupply = await instance.methods.totalSupply().call();

  let approveStaking = await instance.methods.approve(process.env.REACT_APP_DNYANV2_TOKEN_CONTRACT_ADDRESS, Web3Utils.toWei(totalNipSupply.toString())).send({
      from: account
  });

  let success = (approveStaking["status"]) ? true : false;
  return success;
}

async function approveDarkNyanLPStaking(){
  let account = await Accounts.getCurrentAccount();
  let instance = await Contracts.getERC20Functions(process.env.REACT_APP_DARKNYAN_UNI_TOKEN_CONTRACT_ADDRESS); // get V2 DNYAN and approve CATNIP V2 contract on that.
  let totalDNyanSupply = await instance.methods.totalSupply().call();

  let approveStaking = await instance.methods.approve(process.env.REACT_APP_NYANV2_TOKEN_CONTRACT_ADDRESS, Web3Utils.toWei(totalDNyanSupply.toString())).send({
      from: account
  });

  let success = (approveStaking["status"]) ? true : false;
  return success;
}

/**
 * Swap Approvals
 */
async function getMyNyanSwapApproved(){
  let account = await Accounts.getCurrentAccount();
  let result= await Allowance.getNyanSwapAllowance(account);
  if(result > 0) return true;
  return false;
}

async function getMyNipSwapApproved(){
  let account = await Accounts.getCurrentAccount();
  let result= await Allowance.getCatnipSwapAllowance(account);
  if(result > 0) return true;
  return false;
}

async function getMyDNyanSwapApproved(){
  let account = await Accounts.getCurrentAccount();
  let result= await Allowance.getDNyanSwapAllowance(account);
  if(result > 0) return true;
  return false;
}

/*
 * Approved methods for current Account
 */
async function getMyNyanStakeApproved(){
  const account = await Accounts.getCurrentAccount();
  const amount = await Allowance.getNyanStakeAllowance(account);
  return amount > 0;
}


async function getMyCatnipStakeApproved() {
  const account = await Accounts.getCurrentAccount();
  const amount = await Allowance.getCatnipStakeAllowance(account);
  return amount > 0;
}

async function getMyDarkNyanStakeApproved() {
  const account = await Accounts.getCurrentAccount();
  const amount = await Allowance.getDarkNyanStakeAllowance(account);
  return amount > 0;
}




/* APPROVE FUNCTIONS */
async function approveNyanSwap(){
  let account = await Accounts.getCurrentAccount();
  let instance = await Contracts.getERC20Functions(process.env.REACT_APP_NYAN_TOKEN_CONTRACT_ADDRESS);  // get V1 contract
  let totalNyanSupply = await instance.methods.totalSupply().call();

  let approveStaking = await instance.methods.approve(process.env.REACT_APP_NYANV2_TOKEN_CONTRACT_ADDRESS, Web3Utils.toWei(totalNyanSupply.toString())).send({
      from: account
  });

  let success = (approveStaking["status"]) ? true : false;
  return success;
}

/** TODO SCORPZ: CHANGE CONTRACTS TO POINT TO V2 */
async function approveNipSwap(){
  let account = await Accounts.getCurrentAccount();
  let instance = await Contracts.getERC20Functions(process.env.REACT_APP_CATNIP_TOKEN_CONTRACT_ADDRESS);  // get V1 contract
  let totalNipSupply = await instance.methods.totalSupply().call();

  let approveStaking = await instance.methods.approve(process.env.REACT_APP_CATNIPV2_TOKEN_CONTRACT_ADDRESS, Web3Utils.toWei(totalNipSupply.toString())).send({
      from: account
  });

  let success = (approveStaking["status"]) ? true : false;
  return success;
}

/** TODO SCORPZ: CHANGE CONTRACTS TO POINT TO V2 */
async function approveDNyanSwap(){
  let account = await Accounts.getCurrentAccount();
  let instance = await Contracts.getERC20Functions(process.env.REACT_APP_DARKNYAN_TOKEN_CONTRACT_ADDRESS);
  let totalNyanSupply = await instance.methods.totalSupply().call();

  let approveStaking = await instance.methods.approve(process.env.REACT_APP_DNYANV2_TOKEN_CONTRACT_ADDRESS, Web3Utils.toWei(totalNyanSupply.toString())).send({
      from: account
  });

  let success = (approveStaking["status"]) ? true : false;
  return success;
}

export {
  getMyNyanSwapApproved,
  getMyNipSwapApproved,
  getMyDNyanSwapApproved,
  getMyNyanStakeApproved,
  getMyCatnipStakeApproved,
  getMyDarkNyanStakeApproved,
  approveNyanLPStaking,
  approveCatnipLPStaking,
  approveDarkNyanLPStaking,
  approveNyanSwap,
  approveNipSwap,
  approveDNyanSwap
}