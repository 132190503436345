import React, {useState, useEffect }from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import Favicon from "./components/Favicon";
import Header from "./components/Header";
import Routes from "./components/Routes";
import Footer from "./components/Footer";
import TickerComponent from "./components/Ticker";

import { FeatureFlag } from './utils/V2';

import './style/Default.css';
import './style/App.css';

const App = () => {

  const [isFeatureFlagEnabled, setFeatureFlagEnabled] = useState(0);

  // init staking page
  useEffect(() => {
    // only fires whenever requestConnectionToWallet is updated.
    async function init() {
        setFeatureFlagEnabled(await FeatureFlag.isEnabled());
    }
    init()
}, []);

  return (
        
    <Container fluid>
      <Favicon/>
      <Router>
          <Header isFeatureFlagEnabled={isFeatureFlagEnabled} />
          {/* <TickerComponent/> */}
          <Routes isFeatureFlagEnabled={isFeatureFlagEnabled}/>
          <Footer/>
      </Router>
    </Container>
  )
}

export default App;
