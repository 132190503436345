import {Contracts, Accounts, Formatter} from './index';

const Web3Utils = require('web3-utils');


const getGovernanceProposal = async() => {
    
    let instance = await Contracts.getGovernanceVotingContractInstance();
    let proposalId = await instance.methods.proposals(0).call();
    let proposal = await instance.methods.getProposal(proposalId).call();
    let optionVotes = proposal[4];

    let formattedOptionVotes = optionVotes.map(vote => {
        return Formatter.fromWei(vote);
    });

    console.log(formattedOptionVotes);
    // get current block time
    let governanceProposal = {
        question: proposal[0],
        options: proposal[2],
        optionImgs: proposal[3],
        optionVotes: formattedOptionVotes,
        voteEndBlock: proposal[5]
    }
    return governanceProposal;
}

const isGovernanceVotingApproved = async() => {
    let instance = await Contracts.getNyanContractInstance();
    let account = await Accounts.getCurrentAccount();

    let votingAllowance = await instance.methods.allowance(account, process.env.REACT_APP_GOVERNANCE_CONTRACT_ADDRESS).call();
    return votingAllowance > 0;
}

const approveGovernanceVoting = async() => {
    let instance = await Contracts.getNyanContractInstance();
    let account = await Accounts.getCurrentAccount();
    let amount = 33000;

    let approveStaking = await instance.methods.approve(process.env.REACT_APP_GOVERNANCE_CONTRACT_ADDRESS, Web3Utils.toWei(amount.toString())).send({
        from: account
    });
    return;
}

const voteForGovernanceProposal = async(index) => {
    
}

export {
    //getGovernanceProposal,
    //isGovernanceVotingApproved,
   // approveGovernanceVoting,
    //voteForGovernanceProposal
}