import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Navbar, Nav, NavDropdown, FormControl} from 'react-bootstrap';

import { useGlobal } from 'reactn';
import { useHistory } from "react-router-dom";
import { Web3Util } from '../../../utils/index';
import "../../../style/Proposalbuilder.css";

import AddChainBox from './AddChainBox';
import Chains from './Chains';

const ProposalCanvas = (props) => {

    // fetch global data
    const [ fundBid, setFundBid] = useGlobal('fundBid');

    return (
        <Container fluid className="border">
                <Navbar bg="light" expand="lg">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <NavDropdown title="File" id="basic-nav-dropdown">
                            <NavDropdown.Item href="#action/3.1">Import</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">Export</NavDropdown.Item>
                        </NavDropdown>
                        
                        <NavDropdown title="Chain" id="basic-nav-dropdown">
                            <NavDropdown.Item href="#action/3.1">Add chain</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
                </Navbar>

                <Row className="bid-mandatory-container"> 
                    <Form className="bid-form">
                        <Form.Row>
                            <Col lg={1}>
                                <Form.Label>BidID:</Form.Label>
                            </Col>
                            <Col lg={2}>
                                <Form.Control type="text" placeholder="Enter an unique BidID" />
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col lg={1}>
                                <Form.Label>Proposal:</Form.Label> 
                            </Col>
                            <Col lg={3}>
                                <Form.Control type="text" placeholder="Enter the ID" />
                            </Col>
                            <Col>
                                <Button size="sm" className="pink-button">Fetch details</Button>
                            </Col>
                            <Col lg={5}>
                                <sub>(the ID can be found when viewing on our forum: 'http://forum.nyan.finance/proposals/YOURIDISHERE')</sub>
                            </Col>
                        </Form.Row>
                    </Form>
                    
                </Row>
                
                <Row className="bid-chain-container">
                        <Col lg={12}>
                            <Chains fundBid={fundBid}/>
                        </Col>   
                    </Row>

                {/*
                <Row className="border">
                    <Col lg={12}>
                        <AddChainBox/>
                    </Col>
                </Row>
                */}
            


            

        </Container>
    )
}

export default ProposalCanvas