import React, {useState, useRef} from "react";
import { Row, Col, Button, Tooltip, Overlay} from 'react-bootstrap';

import { InfoCircleFill } from 'react-bootstrap-icons';
import '../style/Footer.css';

const Footer = (props) => {
    const [show, setShow] = useState(false);
    const target = useRef(null);

    return (
        <Row className="footer">
            <Col className="addressesCol" lg={9} xs={12}>
            
                <Button ref={target} onClick={() => setShow(!show)} className="pink-button">
                    <InfoCircleFill size={14}/>
                    {!show ? (
                        <span> &nbsp; Show addresses</span>
                    ) : ( 
                        <span> &nbsp; Hide addresses</span>
                    )}
                </Button>
                <Overlay target={target.current} show={show} placement="top">
                    {(props) => (
                    <Tooltip className="addresses-tooltip" id="overlay-example" {...props}>
                        <a href={`https://etherscan.io/token/${process.env.REACT_APP_NYANV2_TOKEN_CONTRACT_ADDRESS}`} target="_new">NYANV2 Token</a><br/>
                        <a href={`https://etherscan.io/token/${process.env.REACT_APP_CATNIPV2_TOKEN_CONTRACT_ADDRESS}`} target="_new">CATNIPV2 Token</a><br/>
                        <a href={`https://etherscan.io/token/${process.env.REACT_APP_DNYANV2_TOKEN_CONTRACT_ADDRESS}`} target="_new">DNYANV2 Token</a>
                    </Tooltip>
                    )}
                </Overlay>
            </Col>
            <Col lg={3} xs={12}>
                <div className="social-box">
                    <a target="_blank" rel="noopener noreferrer" href={"https://github.com/geass-zero/nyan.finance"}>
                    <div className="social-icon git"></div>
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href={"https://www.twitter.com/nyanfinance"}>
                    <div className="social-icon twit"></div>
                    </a> 
                    <a target="_blank" rel="noopener noreferrer" href={"https://t.me/nyanfinance"}>
                    <div className="social-icon tele"></div>
                    </a>
                </div>
            </Col>
        </Row>
    )
}

export default Footer