import React, { useState, useEffect } from "react";
import { Button, Col, Row } from 'react-bootstrap';


const AmountField = (props) => {
    const [amount, setAmount] = useState('')

    /**
     * Set the max amount 
     */
    const setMaxAmount = () => {
        setAmount(props.maxAmount);
        updateInput(props.maxAmount);
    }

    /**
     * Update input, fired from the event
     */
    const updateInput = (e) => {
        let value = e.target ? e.target.value : e;
        if (parseFloat(props.maxAmount) <= parseFloat(value)){
            value = props.maxAmount;
        }
        setAmount(value)
        props.amountInput(value);
        
    }

    useEffect(() => {
        props.amountInput(amount)
    });
    
    return (
        <Row className={`input-amount-row ${props.rowClassName}`}>
            <Col className={`input-amount-col ${props.colClassName}`} lg={10} sm={4} xs={10}>
                <input 
                    className={`input-amount ${props.fieldClassName}`}
                    placeholder="Amount..."
                    value={amount} 
                    onChange={updateInput}
                    type="number"
                    disabled={props.disabled}
                    autoFocus={true}>
                </input>
            </Col>
            <Col lg={2} sm={4} xs={2} className={`align-items-center my-auto ${props.fieldClassName}`}>
                <Button variant="primary pink-button" onClick={setMaxAmount} disabled={props.disabled} className={`max-amount-button ${props.maxButtonClassName}`}>Max</Button>
            </Col>
        </Row>
    )
}

export default AmountField