import React, {useEffect} from "react";
import { Image, Row, Col } from 'react-bootstrap';
import { QuestionCircleFill } from 'react-bootstrap-icons';

import ReadOnlyAmountField from '../fields/ReadOnlyAmountField';
import ReadOnlyHeaderAmountField from '../fields/ReadOnlyHeaderAmountField';


const ExplanationBox = (props) => {

  /*
  props = {
    defaultClassNames = {`explanationbox purple ${stakedNyanAmount > 0  ? "" : "disabled"}`}
    color: purple
    step: 1,
    cardTitle: '',
    cardText: '',
    logoImage: '',
    subText1: 
    balanceText:
    subText2:
    buttons: {

    }
  }
  */

  // init staking page
  useEffect(() => {
    
  }, []);

  return (
    
    <Col lg={3} className={props.defaultClassNames}>
      {/* HEADER */}
      <Row className="explanationbox-header">
          <Col lg={1} className="step-wrapper">
              <div className={`step-wrapper-circle ${props.color}`}>{props.step}</div>
          </Col>
          <Col lg={11}>
              <h4 className="card-title">{props.cardTitle}</h4>
              <p className="card-text" dangerouslySetInnerHTML={{__html: props.cardText ? props.cardText.map((step) => step).join("") : ""}}/>
          </Col>
          {/*
          <Col lg={2}>
              <QuestionCircleFill className="help-icon" size={24}/>
          </Col>
          */}
      </Row>

      {/* BODY */}
      <Row className="explanationbox-body">
          <Col>
              <Row className="justify-content-center align-items-center text-center">
                  <Col>
                      <Image className="explanationbox-logo-image" src={props.logoImage} fluid/>
                  </Col>
              </Row>
              <Row className="justify-content-center align-items-center text-center">
                  <Col className="">
                      <span className="subtext">{props.subText1}</span><br/>
                      
                      <ReadOnlyHeaderAmountField formattedAmount={props.balance.formatted} fullAmount={props.balance.full}/>    
                      
                      <ReadOnlyAmountField formattedAmount={props.availableBalance.formatted} fullAmount={props.availableBalance.full} tokenText={props.availableTokenText}/><br/>
                      {props.available2Balance && 
                         <ReadOnlyAmountField formattedAmount={props.available2Balance.formatted} fullAmount={props.available2Balance.full} tokenText={props.available2TokenText}/>
                      }
                      <span className="subtext">{props.subText3}</span>
                  </Col>
              </Row>
          </Col> 
      </Row>

      {/* FOOTER */}
      <Row className="justify-content-center align-items-center explanationbox-footer">
          <Col className="buttonRow text-center">
              {props.buttons}
          </Col>
      </Row>
    
    </Col>
    
  )


}


export default ExplanationBox;




