import { getDarkNyanRewards } from '../Balances';
import { Formatter, Accounts, Web3Util } from '../index';
import { getInstance } from '../Web3';
import { Contracts } from './index';



async function getMyNFTDetails(){
  let account = await Accounts.getCurrentAccount();
  return getNFTDetails(account);
}

async function getNFTDetails(account){

  let instance = await Contracts.getNFTContractInstance();
  let instance2 = await Contracts.getNFTDataContractInstance();

  let balance = await instance.methods.balanceOf(account).call();
  let tier = false;

  if(balance > 0){
    
    let tokenID = await instance.methods.tokenOfOwnerByIndex(account, 0).call();
    let tokenURI = await instance.methods.tokenURI(tokenID).call(); // re-factor later
    
    let nftDetails = await instance2.methods.getNFTDetails(tokenID).call();
    let tierDetails = determineTierAndDescription(nftDetails.tier);
    
    return {
      id: tokenID,
      tier: tierDetails,
      nft: nftDetails
    }
  }
  return tier;
}


function determineTierAndDescription(tierName){
  //tierName='LEGENDARY';
//function determineTierAndDescription(tierID){ change this when tokenuri works
  const tiers = require(`../../assets/data/nft/nftmetadata.json`);
  const tier = tiers.find(tier => tier.tier == tierName);
  return tier;
}

async function stakeNFT(id) {
  let instance = await Contracts.getNFTDataContractInstance();
  let account = await Accounts.getCurrentAccount();
  
  let result = await instance.methods.stakeNFT(parseInt(id)).send({
      from: account
  });
  return result; 
}

async function unstakeNFT(id) {
  let instance = await Contracts.getNFTDataContractInstance();
  let account = await Accounts.getCurrentAccount();
  
  let result = await instance.methods.unstakeNFT(parseInt(id)).send({
      from: account
  });
  return result; 
}

export {
  getMyNFTDetails,
  getNFTDetails,
  stakeNFT,
  unstakeNFT
}