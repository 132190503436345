import { Accounts, Contracts } from "./index"

const Web3Utils = require('web3-utils');


/** DUPLICATE? */
async function unstakeMyNyan(amount){
    let instance = await Contracts.getCatnipContractInstance();
    let formattedAmount = this.web3.utils.toWei(amount.toString());

    try {
        let unstakeRes = await instance.methods.withdraw(formattedAmount).send({
            from: this.accounts[0]
        });
    
        if (unstakeRes["status"]) {
            this.setState({isWithdrawing: false, stakeAmount: 0});
            this.getMyStakeAmount();
        } else {
            this.setState({isWithdrawing: false});
        }
    } catch (error) {
        console.log(error);
    }
}


/**
 * amount of NYAN to withdraw from the staked contract.
 * @param {String} amountToWithdraw 
 */
const withdrawNyan = async (amountToWithdraw) => {
    let account = await Accounts.getCurrentAccount();
    let instance = await Contracts.getCatnipContractInstance();

    try {
        let unstakeRes = await instance.methods.withdraw(Web3Utils.toWei(amountToWithdraw.toString())).send({
            from: account
        });
    
        if (unstakeRes["status"]) {
            return {
                success: true
            }
        } else {
            return {
                success: false
            }
        }
    } catch (error) {
        console.log(error);
    }
}

const withdrawCatNipUni = async (amountToWithdraw) => {
    let account = await Accounts.getCurrentAccount();
    let instance = await Contracts.getDarkNyanContractInstance();

    try {
        let unstakeRes = await instance.methods.withdrawCatnipUni(Web3Utils.toWei(amountToWithdraw.toString())).send({
            from: account
        });
    
        if (unstakeRes["status"]) {
            return {
                success: true
            }
        } else {
            return {
                success: false
            }
        }
    } catch (error) {
        console.log(error);
    }
}

// Export each function
export {
    withdrawNyan,
    withdrawCatNipUni
 };
