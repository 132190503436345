/** All interations with the contracts */
import { Contracts } from '../index';
import { Accounts } from '../../index';
 
async function harvestMyCatnipRewards(){
    let instance = await Contracts.getCatnipV2DataContractInstance();
    let currentAccount = await Accounts.getCurrentAccount();
    instance.methods.getReward().send({
        from: currentAccount
    });
}

const harvestMyNyanRewards = async() => {
    let instance = await Contracts.getNyanV2DataContractInstance();
    let currentAccount = await Accounts.getCurrentAccount();
    instance.methods.getNyanRewards().send({
        from: currentAccount
    });
}

const harvestMyDarkNyanRewards = async() => {
    let instance = await Contracts.getDarkNyanContractInstance();
    let currentAccount = await Accounts.getCurrentAccount();
    instance.methods.getReward().send({
        from: currentAccount
    });
}

// Export each function
export {
    harvestMyCatnipRewards,
    harvestMyNyanRewards,
    harvestMyDarkNyanRewards
 };