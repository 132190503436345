import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import stakeLogo from '../assets/Nyan_stake.gif';
import mineLogo from '../assets/Dark_Nyan.gif';
import pumpLogo from '../assets/Nyan_pump.gif';
import BusinessCatLogo from '../assets/logos/business-cat.png';

import { Swap, Balances } from '../utils';

import '../style/Home.css';

const Home = (props) => {

    const [isEligibleForNyanV1toV2Swap, setIsEligibleForV1ToV2Swap] = useState(false);
    const [isLGEOver, setIsLGEOver] = useState(false);
    const [nyanBalance, setNyanBalance]  = useState(0);
   
    // init staking page
    useEffect(() => {
        // only fires whenever requestConnectionToWallet is updated.
        async function init() {
            setNyanBalance(await Balances.getMyNyanBalance());  
            setIsEligibleForV1ToV2Swap(await Swap.isEligibleForNyanV1toV2Swap());
            setIsLGEOver(await Swap.getLGEBool());
        }
        init()
    }, []);

    return (
        <Container fluid>

            <br/>
            <Row className="justify-content-center align-items-start">
                <Col lg="auto" className=""></Col>
                {!isEligibleForNyanV1toV2Swap ? (
                    <Col lg={6} sm={12}>
                        <div className="align-items-center text-center subpage-header">
                        <Image src={BusinessCatLogo} className="subpage-headder-image"/>
                        <span className="subpage-header-title ">FUND MANAGEMENT</span>
                        <p className="text-center subpage-header-subtext">New to Yield farming? Read our extensive guide <a target="_blank" rel="noopener noreferrer" href="https://www.learnnyan.finance/start-here/intro-to-usdnyan-+-usdnip">here</a></p>
                        </div>
                    </Col>
                ) : (
                    <Col>
                        <Row className="justify-content-center align-items-center">
                            <Col lg={6} sm={12} className="align-items-center text-center purple-box">
                                <Badge variant="primary">New</Badge>
                                <span className="subpage-header-title text-center">V1 TOKENS DETECTED!</span>
                                <Badge variant="primary">New</Badge>
                                <p className="text-center subpage-header-subtext">
                                    We detected V1 Tokens in your wallet. <br/>
                                    Swap them now for V2 Tokens and enjoy additional benefits!<br/>
                                    <b><a href="/app/swap">Go to SWAP page</a></b>
                                </p>
                            </Col>
                        </Row>
                    </Col>
                )  }
                <Col lg="auto" className=""></Col>
            </Row>
            
            
            <br/><br/><br/>

            <Row className="justify-content-center align-items-center">
                <Col lg="auto"></Col>

                <Col lg={3} sm={12}>
                    <Link to="/app/stake" className="router-link">
                        <div className="card actionbox">
                            <div className="card-horizontal">
                                <div className="img-square-wrapper">
                                    <img className="actionbox-logo-image" alt="nyan logo" src={stakeLogo}/>
                                </div>
                                <div className="card-body actionbox-body">
                                    <h4 className="card-title">Stake</h4>
                                    <p className="card-text">Stake NYANV2 LP and earn CATNIPV2!</p>
                                    <h4 className="card-subtitle">AVAILABLE NOW!</h4>
                                </div>
                            </div>
                        </div>
                    </Link>
                </Col>

                <Col lg={3} sm={12}>
                    <Link to="/app/mine" className="router-link">
                        <div className="card actionbox">
                            <div className="card-horizontal">
                                <div className="img-square-wrapper">
                                    <img className="actionbox-logo-image" alt="nyan logo" src={mineLogo}/>
                                </div>
                                <div className="card-body actionbox-body">
                                    <h4 className="card-title">Mine</h4>
                                    <p className="card-text">Provide liquidity and earn DNYANV2</p>
                                    <h4 className="card-subtitle">AVAILABLE NOW!</h4>
                                </div>
                            </div>
                        </div>
                    </Link>
                </Col>
                <Col lg={3} sm={12}>
                   
                   {/*<Link to="/app/fund/manage" className="router-link">*/}
                        <div className="card actionbox disabled">
                            <div className="card-horizontal">
                                <div className="img-square-wrapper">
                                    <img className="actionbox-logo-image" alt="nyan logo" src={pumpLogo}/>
                                </div>
                                <div className="card-body actionbox-body">
                                    <h4 className="card-title">Hedgefund</h4>
                                    <p className="card-text">See Hedgefund bids & start voting</p>
                                    <h4 className="card-subtitle">AVAILABLE SOON!</h4>
                                </div>
                            </div>
                        </div>
                    {/*</Link>*/}
                </Col>
                <Col lg="auto"></Col>
            </Row>
            
           
                <Row className="learn-more">
                    <Col lg="auto">Pro Tip: Staking DNYANV2 liquidity gives your NyanV2 rewards and catnip mining a boost. <br/>
                        Learn more about NYANV2, CATNIPV2 and DNYAN and the ecosystem in our&nbsp; 
                        <a target="_blank" rel="noopener noreferrer" href="https://www.learnnyan.finance/start-here/intro-to-usdnyan-+-usdnip">
                            Gitbook.
                        </a>
                    </Col>
                </Row>
            
        </Container>
    )
}

export default Home;