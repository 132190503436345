import React, { useState, useEffect } from "react";


const ReadOnlyHeaderAmountField = (props) => {
    const [formattedAmount, setFormattedAmount] = useState('')
    const [fullAmount, setFullAmount] = useState('')
    const [showExact, setShowExact] = useState(false);
    
    useEffect(() => {
      setFormattedAmount(props.formattedAmount);
      setFullAmount(props.fullAmount);
    }, [props.formattedAmount, props.fullAmount]);
    
    
    return (
        <div>
          {!showExact ? (
            <div className="card-title h5">{formattedAmount}</div>
          ) : (
            <div className="card-title h5">{fullAmount}</div>
          )}
          <div><a onClick={() => setShowExact(!showExact)} className="link pink-link">{showExact ? 'less' : 'exact' }</a></div>
        </div>
    )
}

export default ReadOnlyHeaderAmountField