import { Accounts } from "../index";
import { Contracts } from './index';

const Web3Utils = require('web3-utils');

/*Examples:
-To stake dNyan-2 LP, you'd have to approve the dNyanLP on the Nyan-v2 contract.
-To stake NIP-2 LP, you'd have to approve the NIP-2 LP on the dNyan-v2 contract
*/

/**
 * amount of NYAN to withdraw from the staked contract.
 * @param {String} amountToWithdraw 
 */
const stakeNyanV2LP = async (amountToStake) => {
    let account = await Accounts.getCurrentAccount();
    let instance = await Contracts.getNyanContractInstance();

    let result = await instance.methods.stakeNyanV2LP(Web3Utils.toWei(amountToStake.toString())).send({
        from: account
    });
    return result;
}

const unstakeNyanV2LP = async (amountToUnStake) => {
    let account = await Accounts.getCurrentAccount();
    let instance = await Contracts.getNyanContractInstance();
    
    let result = await instance.methods.unstakeNyanV2LP(Web3Utils.toWei(amountToUnStake.toString())).send({
        from: account
    });
    return result;
}


  const stakeCatnipV2LP = async(amountToStake) => {
    let account = await Accounts.getCurrentAccount();
    let instance = await Contracts.getDarkNyanContractInstance();
    
    let result = await instance.methods.stakeCatnipV2LP(Web3Utils.toWei(amountToStake.toString())).send({
        from: account
    });
    return result;
  }

  const unstakeCatnipV2LP = async(amountToUnStake) => {
    let account = await Accounts.getCurrentAccount();
    let instance = await Contracts.getDarkNyanContractInstance();

    let result = await instance.methods.unstakeCatnipV2LP(Web3Utils.toWei(amountToUnStake.toString())).send({
        from: account
    });
    return result;
  }

  const stakeDNyanV2LP = async(amountToStake) => {
    let account = await Accounts.getCurrentAccount();
    let instance = await Contracts.getNyanContractInstance();
    
    let result = await instance.methods.stakeDNyanV2LP(Web3Utils.toWei(amountToStake.toString())).send({
        from: account
    });
    return result;
  }

  const unstakeDNyanV2LP = async(amountToUnStake) => {
    let account = await Accounts.getCurrentAccount();
    let instance = await Contracts.getNyanContractInstance();

    let result = await instance.methods.unstakeDNyanV2LP(Web3Utils.toWei(amountToUnStake.toString())).send({
        from: account
    });
    return result;
  }

  
  

// Export each function
export {
    stakeNyanV2LP,
    unstakeNyanV2LP,
    stakeCatnipV2LP,
    unstakeCatnipV2LP,
    stakeDNyanV2LP,
    unstakeDNyanV2LP
 };
