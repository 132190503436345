
import React, { useState, useEffect, useContext  } from "react";

import {Button, Row, Col, Container, Card} from 'react-bootstrap';

import Catlogo from '../../../assets/logos/cat-logo.png';

import { ArrowDownCircleFill } from 'react-bootstrap-icons';

import AmountField from '../../fields/Amount';

import ReadOnlyAmountField from '../../fields/ReadOnlyAmountField';

import '../../../style/Swap.css';

const swapAmountClassNames = {
  rowClassName: 'row-swap-amount',
  colClassName: 'col-swap-amount',
  fieldClassName: 'field-swap-amount',
  maxButtonClassName: 'field-swap-max-button'
}


const SwapCard = (props) => {

  return (
        <Card className="swap-card">
            <div className="text-center">
                <Card.Img className="actionbox-logo-image top" variant="top" src={props.logo}/>
            </div>
            <Card.Body>
                <Card.Title className="card-title">{props.v1Text}</Card.Title>
                <Card.Text className="card-text">
                    <ReadOnlyAmountField formattedAmount={props.v1Balance.formatted} fullAmount={props.v1Balance.full} tokenText="tokens available"/>
                </Card.Text>
                <br/>
                <div className="d-flex justify-content-center">
                {!props.approved ? (
                  <Button size="lg" className="pink-button actionbox-button swap-button" variant="primary" onClick={() => props.approveFunction()}>Approve contract</Button>
                  ) : (
                  <AmountField {...swapAmountClassNames} maxAmount={props.v1Balance.full} amountInput={props.setAmountFN} disabled={props.v1Balance.full < 0.001 || !props.approved}></AmountField>
                )}
                </div>
                <br/><br/>
                <div>
                  <ArrowDownCircleFill size={50+'%'} className="swapDownArrowIcon"/>
                </div>
                
                <br/>
                <Card.Title className="card-title">{props.v2Text}</Card.Title>
                <Card.Text className="card-text">
                    <ReadOnlyAmountField formattedAmount={props.v2Balance.formatted} fullAmount={props.v2Balance.full} tokenText="swapped"/>
                </Card.Text>

                {props.approved && 
                  <Button size="lg" className="pink-button actionbox-button swap-button" variant="primary" disabled={props.v1Balance.full < 0.001} onClick={() => props.swapFunction()}>Swap!</Button>
                }
                
            </Card.Body>
        </Card>
    )
}

export default SwapCard;