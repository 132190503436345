import React, { useState, useEffect } from "react";

import { Container, Row, Col, Modal, Button, Image } from 'react-bootstrap';
import BidDescription from './BidDescription';

const Chains = (props) => {
    
    const [bids, setBids] = useState([]);

    // init staking page
    useEffect(() => {
        // only fires whenever requestConnectionToWallet is updated.
        
        const data = props.fundBid.chainBids.map((bid, index) => {
            return  <BidDescription bid={bid} key={index}/>
        })
        
        setBids(data);

    }, [])

    return (
        <Row className="">
            <Col className="text-center">
                {bids}
            </Col>
        </Row>    
    )
}
export default Chains