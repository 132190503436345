/** All interations with the contracts */
import { Web3Util, Accounts } from './index';
import ERC20 from "../contracts/ERC20.json";
import NyanV2 from "../contracts/NyanV2.json";
import CatnipV2 from "../contracts/CatnipV2.json";
import DarkNyanV2 from "../contracts/CatnipV2.json";
import NyanToken from "../contracts/NyanToken.json";
import CatnipToken from "../contracts/CatnipToken.json";
import DarkNyan from "../contracts/DarkNyan.json";
import DarkNyanUni from "../contracts/DarkNyanUni.json";
import NyanGovernance from "../contracts/NyanGovernance.json";
import NyanVoting from "../contracts/NyanVoting.json";

// formatter
const Web3Utils = require('web3-utils');

async function getNyanV2ContractInstance(){
    if(!window.nyanV2CI) {
        const instance = await Web3Util.getInstance();
        window.nyanV2CI = new instance.eth.Contract(
            NyanV2.abi,
            process.env.REACT_APP_NYANV2_TOKEN_CONTRACT_ADDRESS
        );
    }
    return window.nyanV2CI
}

async function getCatnipV2ContractInstance(){
    if(!window.catnipV1CI) {
        const instance = await Web3Util.getInstance();
        window.catnipV1CI = new instance.eth.Contract(
            CatnipV2.abi,
            process.env.REACT_APP_CATNIPV2_TOKEN_CONTRACT_ADDRESS
        );
    }
    return window.catnipV1CI
}

async function getDarkNyanV2ContractInstance(){

    if(!window.darkNyanV2CI) {
        const instance = await Web3Util.getInstance();
        window.darkNyanV2CI = new instance.eth.Contract(
            DarkNyanV2.abi,
            process.env.REACT_APP_DNYANV2_TOKEN_CONTRACT_ADDRESS
        );
    }
    return window.darkNyanV2CI
}

async function getNyanV2LPContractInstance(){
    if(!window.nyanV2LPCI) {
        const instance = await Web3Util.getInstance();
        window.nyanV2LPCI = new instance.eth.Contract(
            ERC20.abi,
            process.env.REACT_APP_NYANV2LP_TOKEN_CONTRACT_ADDRESS
        );
    }
    return window.nyanV2LPCI
}

async function getCatnipV2LPContractInstance(){
    if(!window.catnipV2LPCI) {
        const instance = await Web3Util.getInstance();
        window.catnipV2LPCI = new instance.eth.Contract(
            ERC20.abi,
            process.env.REACT_APP_CATNIPV2LP_TOKEN_CONTRACT_ADDRESS
        );
    }
    return window.catnipV2LPCI
}

async function getDarkNyanV2LPContractInstance(){
    if(!window.darkNyanV2LPCI) {
        const instance = await Web3Util.getInstance();
        window.darkNyanV2LPCI = new instance.eth.Contract(
            ERC20.abi,
            process.env.REACT_APP_DARKNYANV2LP_TOKEN_CONTRACT_ADDRESS
        );
    }
    return window.darkNyanV2LPCI
}

async function getNyanContractInstance(){
    if(!window.nyanCI) {
        const instance = await Web3Util.getInstance();
        window.nyanCI = new instance.eth.Contract(
            NyanToken.abi,
            process.env.REACT_APP_NYAN_TOKEN_CONTRACT_ADDRESS
        );
    }
    return window.nyanCI
}


async function getCatnipContractInstance(){
    if(!window.catnipCI) {
        const instance = await Web3Util.getInstance();
        window.catnipCI = new instance.eth.Contract(
            CatnipToken.abi,
            process.env.REACT_APP_CATNIP_TOKEN_CONTRACT_ADDRESS
        );
    }
    return window.catnipCI
}

async function getCatnipUniContractInstance(){
    if(!window.catnipUniCI) {
        const instance = await Web3Util.getInstance();
        window.catnipUniCI = new instance.eth.Contract(
            DarkNyanUni,
            process.env.REACT_APP_CATNIPUNI_TOKEN_CONTRACT_ADDRESS
        );
    }
    return window.catnipUniCI
}

async function getDarkNyanContractInstance(){
    if(!window.dNyanCI) {
        const instance = await Web3Util.getInstance();
        console.log(instance);
        console.log(process.env.REACT_APP_DARKNYAN_TOKEN_CONTRACT_ADDRESS);
        window.dNyanCI = new instance.eth.Contract(
            DarkNyan.abi,
            process.env.REACT_APP_DARKNYAN_TOKEN_CONTRACT_ADDRESS
        );
        console.log(window.dNyanCI)
    }
    return window.dNyanCI
}

async function getDarkNyanUniContractInstance(){
    if(!window.dNyanUniCI) {
        const instance = await Web3Util.getInstance();
        window.dNyanUniCI = new instance.eth.Contract(
            DarkNyanUni,
            process.env.REACT_APP_DARKNYAN_UNI_TOKEN_CONTRACT_ADDRESS
        );
    }
    return window.dNyanUniCI
}

async function getNyanVotingContractInstance(){
    if(!window.nyanVotingCI) {
        const instance = await Web3Util.getInstance();
        window.nyanVotingCI = new instance.eth.Contract(
            NyanVoting.abi,
            process.env.REACT_APP_VOTING_CONTRACT_ADDRESS
        );
    }
    return window.nyanVotingCI
}

async function getGovernanceVotingContractInstance(){
    if(!window.governanceVotingCI) {
        const instance = await Web3Util.getInstance();
        window.governanceVotingCI = new instance.eth.Contract(
            NyanGovernance.abi,
            process.env.REACT_APP_GOVERNANCE_CONTRACT_ADDRESS
        );
    }
    return window.governanceVotingCI
}

/**
 * Contract approval methods
 */
async function getMyNyanApproved(){
    let account = await Accounts.getCurrentAccount();
    let result= await getNyanAllowance(account);
    if(result > 0) return true;
    return false;
}

async function getMyNipUniApproved(){
    let account = await Accounts.getCurrentAccount();
    let result = await getNipUniAllowance(account);
    if(result > 0) return true;
    return false;
}

/**
 * Contract approval setters
 * @param {} account 
 */
async function approveNyanV2LPStaking(){
    let account = await Accounts.getCurrentAccount();
    let instance = await getNyanV2LPContractInstance();
    let totalNyanSupply = await instance.methods.totalSupply().call();

    let approveStaking = await instance.methods.approve(process.env.REACT_APP_NYANV2_TOKEN_CONTRACT_ADDRESS, Web3Utils.toWei(totalNyanSupply.toString())).send({
        from: account
    });

    let success = (approveStaking["status"]) ? true : false;
    return success;
}

async function approveCatnipV2LPStaking(){
    let account = await Accounts.getCurrentAccount();
    let instance = await getCatnipV2LPContractInstance();
    let totalNyanSupply = await instance.methods.totalSupply().call();

    let approveStaking = await instance.methods.approve(process.env.REACT_APP_CATNIPV2_TOKEN_CONTRACT_ADDRESS, Web3Utils.toWei(totalNyanSupply.toString())).send({
        from: account
    });

    let success = (approveStaking["status"]) ? true : false;
    return success;
}

async function approveDarkNyanV2LPStaking(){
    let account = await Accounts.getCurrentAccount();
    let instance = await getNyanContractInstance();
    let totalNyanSupply = await instance.methods.totalSupply().call();

    let approveStaking = await instance.methods.approve(process.env.REACT_APP_DNYANV2_TOKEN_CONTRACT_ADDRESS, Web3Utils.toWei(totalNyanSupply.toString())).send({
        from: account
    });

    let success = (approveStaking["status"]) ? true : false;
    return success;
}

async function approveNyanStaking(){
    let account = await Accounts.getCurrentAccount();
    let instance = await getNyanContractInstance();
    let totalNyanSupply = await instance.methods.totalSupply().call();

    let approveStaking = await instance.methods.approve(process.env.REACT_APP_CATNIP_TOKEN_CONTRACT_ADDRESS, Web3Utils.toWei(totalNyanSupply.toString())).send({
        from: account
    });

    let success = (approveStaking["status"]) ? true : false;
    return success;
}


async function approveNipUniStaking(){
    let account = await Accounts.getCurrentAccount();
    let instance = getDarkNyanContractInstance();
    let totalNipUniSupply = await instance.methods.totalSupply().call();

    let approveStaking = await instance.methods.approve(process.env.REACT_APP_DARKNYAN_TOKEN_CONTRACT_ADDRESS, Web3Utils.toWei(totalNipUniSupply.toString())).send({
        from: account
    });

    let success = (approveStaking["status"]) ? true : false;
    return success;
}

async function getNyanAllowance(account){
    let instance = await getNyanContractInstance();
    let nyanAllowance = await instance.methods.allowance(account, process.env.REACT_APP_CATNIP_TOKEN_CONTRACT_ADDRESS).call();
    return nyanAllowance;
  }
  
  async function getNipUniAllowance(account){
    let instance = await getDarkNyanContractInstance();
    let nipUniAllowance = await instance.methods.allowance(account, process.env.REACT_APP_DARKNYAN_TOKEN_CONTRACT_ADDRESS).call();
    return nipUniAllowance;
  }


 // Export each function
 export {
    getNyanV2ContractInstance,
    getCatnipV2ContractInstance,
    getDarkNyanV2ContractInstance,
    getNyanV2LPContractInstance,
    getCatnipV2LPContractInstance,
    getDarkNyanV2LPContractInstance,
    getNyanContractInstance,
    getCatnipContractInstance,
    getDarkNyanContractInstance,
    getDarkNyanUniContractInstance,
    getCatnipUniContractInstance, // proxy for getDarkNyanUniToken
    getMyNyanApproved,
    getNyanAllowance,
    getMyNipUniApproved,
    getNipUniAllowance,
    approveNyanV2LPStaking,
    approveCatnipV2LPStaking,
    approveDarkNyanV2LPStaking,
    approveNyanStaking,
    approveNipUniStaking,
    getGovernanceVotingContractInstance,
    getNyanVotingContractInstance
 };