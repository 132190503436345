import React, {useEffect, useState} from "react";
import { Container, Row, Col, Card, Button} from 'react-bootstrap';

import '../../style/Account.css';
//import LegendaryLogo from '../../assets/logos/nft/legendary-tier.jpg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRocketLaunch } from '@fortawesome/pro-solid-svg-icons';

import { NFT } from '../../utils/V2';

const Account = (props) => {

    const [accountDetails, setAccountDetails] = useState(null);

    const stakeNFT = () => {
        if(accountDetails.id) NFT.stakeNFT(accountDetails.id);
    }

    const unstakeNFT = () => {
        if(accountDetails.id) NFT.unstakeNFT(accountDetails.id);
    }

    // init staking page
    useEffect(() => {
        // only fires whenever requestConnectionToWallet is updated.
        async function init() {
            setAccountDetails(await NFT.getMyNFTDetails());
        }
        init()
    }, []);

    return (
        <Container fluid>
            
            {accountDetails ? (
                <div>
                    <Row>
                        <Col></Col>
                        <Col lg={6}>
                            <Card className="actionbox text-center">
                                
                                <Card.Body className="actionbox-body text-center">
                                    <Card.Title className={`account-tier ${accountDetails.tier.tier.toLowerCase()}`}>
                                        {accountDetails.tier.title}
                                    </Card.Title>
                                    <div className="text-center">
                                        <Card.Img fluid="true" className={`nftbox-logo-image top ${accountDetails.tier.tier.toLowerCase()}`} variant="top" src={`${accountDetails.tier.id}`}/>
                                    </div>
                                    <div className="card-text">
                                        <div className="text-center tier-description">{accountDetails.tier.description}</div>
                                        <div className="text-center placeholder-icon">
                                            <FontAwesomeIcon icon={faRocketLaunch} size="3x" className="rocket-icon"/>
                                        </div>
                                        
                                        <div className="text-center">
                                            The Nyanko NFT gives a <b>{accountDetails.tier.bonus}%</b> boost to CATNIP-V2 and DNYAN-V2 mining!
                                        </div>
                                    </div>
                                    {accountDetails.nft.isStaked ? (
                                        <Button className="pink-button actionbox-button" variant="primary" disabled={!accountDetails.nft.isStaked} onClick={unstakeNFT}>Unstake</Button>
                                    ) : (
                                        <Button className="pink-button actionbox-button" variant="primary" disabled={accountDetails.nft.isStaked} onClick={stakeNFT}>Stake</Button>
                                    )}
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col></Col>
                    </Row>
                </div>
            ) : (
                <Row>
                     <Col lg="auto">No contribution plegded</Col>
                </Row>
            )}
            
            
        </Container>
    )
}

export default Account