/** All interations with the contracts */
import { Balances as V1Balances, Formatter } from './index';
import { LGE } from './V2/index';
/**
 * Eligible for V2 Swap of NYAN.
 */
async function isEligibleForNyanV1toV2Swap(){
    const cBalance = await V1Balances.getMyCatnipBalance();
    const dnBalance = await V1Balances.getMyDarkNyanBalance();
    const nBalance = await V1Balances.getMyNyanBalance();
    const isEligible = ( cBalance > 0.01 || dnBalance > 0.01 || nBalance > 0.01);
    return isEligible;
}


async function getLGEBool(){
    let status = await LGE.isETHLGEOver();
    return status;
}

async function swapForLGE(nyanAmount){
    let fee = parseInt(nyanAmount) / 10;
    nyanAmount = Formatter.toWei(nyanAmount.toString());
    fee = Formatter.toWei(fee.toString());
    let status = await LGE.addNyanAndEth(nyanAmount, fee);
    return status;
}

// Export each function
export {
    isEligibleForNyanV1toV2Swap,
    getLGEBool,
    swapForLGE
 };

