import React, { useState, useRef } from "react";
import { Modal, Button, Row, Col } from 'react-bootstrap';

import AddressTypeAhead from "../input/AddressTypeAhead";
import AmountField from "../input/AmountField";


const AddApprovalBox = (props) => {

    const [tokenAddress, setTokenAddress] = useState(0);
    const [spenderAddress, setSpenderAddress] = useState(0);
    const [amountToApprove, setAmountToApprove] = useState(0);

    const ref = useRef();

    const inputAmountClassNames = {
        
    }

    return (
        <Modal size="lg" className="modal-box" centered show={props.isModalShown} onHide={props.hideModal}>
            <Modal.Header>
                <Modal.Title className="modal-box-title">Add approval function</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                
                <Row className="justify-content-left text-left"> 
                    <Col>
                        Add an approval function to make sure the interaction between contracts is allowed.
                    </Col>
                </Row>

                <br/>
                <Row className="justify-content-right text-left">
                    <Col> The token address being used:</Col>
                </Row>
                <Row>
                    <Col>
                        <AddressTypeAhead id="token-input-field"/>
                    </Col>
                </Row>
                
                <br/>
                <Row className="justify-content-right text-left">
                    <Col> The spender address: </Col>
                </Row>
                <Row>
                    <Col>
                        <AddressTypeAhead id="spender-input-field"/>
                    </Col>
                </Row>
                
                <br/>
                <Row className="justify-content-right text-left">
                    <Col> Amount to approve: in WEI</Col>
                </Row>
                
                <Row className="justify-content-right text-left">
                    <Col>
                        <AmountField/>
                    </Col>
                </Row>

                

                

            </Modal.Body>
            <Modal.Footer className="justify-content-center align-items-center">
                <Button variant="primary brown-button" onClick={props.hideModal}>
                    Cancel
                </Button>
                <Button variant="primary pink-button" onClick={() => props.addApprovalFunction()}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddApprovalBox