import { setGlobal } from 'reactn';

import { Contracts } from './utils';


(async () => {
	
  //https://medium.com/@Charles_Stover/manage-global-state-with-react-hooks-6065041b55b4
  setGlobal({
    web3Instance: false,
    web3Modal: false,
    walletIsConnected: false,
    requestConnectionToWallet: false,
    accounts: {},
    nyanApproved: false,
    nyanApprovalInProgress: false,
    nipUniApproved: false,
    nipUniApprovalInProgress: false,
    whiteListedAddresses: ['dfF2'],
    fundBid: {
      chainBids: [
        {
          proposalId: 'UNIQUE-ID',
          interface: 'ERC20',
          functionName: 'Swap',
          functionID: 'SWAP',
          addresses: [],
          integers: [],
          strings: [],
          bytes: []
        }
      ]
    }
    
  });

})();




